import styled from 'styled-components';
import { colorSet, colors } from '../../style/colorVar';
import { getCardData, getWAU } from '../../apis/api/kpis';
import { getCardDataList, getWAUList } from '../../apis/services/kpis';
import { useEffect, useState, forwardRef } from 'react';
//import { ClickRateChart, FunnelChart } from './Charts';
import { TopNav, TopNavDLST } from './TopNav';
import { ChartBox, ChartTitle, Container, FlexContainer, GridContainer, Spacer, TextDeco } from '../../style/Units';
import { ARPPUChart, CartNPurchaseChart, ConversationChart, ConversionRateChart, SalesChart, UsersChart, FunnelChart } from './DashboardCharts';
import dashboardStyle from '../../style/dashboard.module.css';
import Dropdown from '../Units/Dropdown';
import DateRangePicker from '../Units/DateRangePicker';
import { fetchDashboardData } from '../../apis/api/indicators/api';
import { getFormattedNumberValue, getFormattedDecimalValue, getFormattedDate } from '../../apis/api/utils';

const Dashboard = () => {
    const partnerId = sessionStorage.getItem('ptid');
    const [startDate, setStartDate] = useState(getFormattedDate(Date.now() - 7 * 24 * 60 * 60 * 1000));
    const [endDate, setEndDate] = useState(getFormattedDate());
    const channels = [{ title: '전체', value: 'all' }, { title: '모바일', value: 'mobile' }, { title: '웹', value: 'web' }];
    const [selectedChannels, setSelectedChannels] = useState([channels[0]]);
    const [channelValues, setChannelValues] = useState([true, true, true]);
    const [isLoading, setIsLoading] = useState(true);
    const [dashboardData, setDashboardData] = useState();
    const [noData, setNoData] = useState([false, false, false]);

    const dbLineGraphLTextContents = ['총 장바구니 담기 건수', '총 장바구니 담기 금액', '총 구매 건수', '총 구매 금액'];
    const dbBarGraphLTextContents = ['유입률', '클릭률', '구매율'];

    const updateDashboardData = (key, newData) => {
        const deepCopiedData = structuredClone(newData);

        setDashboardData((prev) => ({
            ...prev,
            [key]: deepCopiedData,
        }));
    }

    useEffect(() => {
        const channelConcat = selectedChannels.map(channel => channel.value).join(',');
        fetchNewSettingData(startDate, endDate, channelConcat, partnerId);
    }, []);

    useEffect(() => {
        const channelConcat = selectedChannels.map(channel => channel.value).join(',');
        fetchNewSettingData(startDate, endDate, channelConcat, partnerId);
    }, [startDate, endDate, selectedChannels]);

    const fetchNewSettingData = (startDate, endDate, channel, partnerId) => {
        setIsLoading(true);
        if (channel.length === 0) {     // 채널 선택 없음
            setNoData(() => [true, true, true]);
            setIsLoading(false);
            return;
        }
        const formattedStartDate = getFormattedDate(startDate);
        if (!endDate) return;
        const formattedEndDate = getFormattedDate(endDate);
        fetchDashboardData(formattedStartDate, formattedEndDate, channel, partnerId)
            .then(res => {
                const newNoData = [false, false, false];
                if ((res[0].length===0) || (res[0][0].series.data.length === 0)) newNoData[0] = true;
                updateDashboardData('lineS', res[0]);
                if ((res[1].length === 0) || (res[1][0].multiSeries[0].data.length === 0)) {
                    newNoData[1] = true;
                    updateDashboardData('lineL', res[1]);
                } else {
                    newNoData[1] = false;
                    // check missing dates first
                    const filledMultiseries = fillMultiSeriesMissingDates(res[1][0].multiSeries);
                    const filledLineL = [{ ...res[1][0], multiSeries: filledMultiseries}]
                    updateDashboardData('lineL', filledLineL);
                }
                if ((res[2].length === 0) || (res[2][0].series.data.length === 0) || (res[2][0].textDatas[0].value === null)) {
                    newNoData[2] = true;
                }
                const tooSmallValues = res[2][0].series.data.filter(data => data.y < 6);
                if (tooSmallValues.length === 5) {
                    newNoData[2] = true;
                }
                updateDashboardData('barL', res[2]);
                setNoData(newNoData);
                setIsLoading(false);
            })
    }
 
    return (
        <div className={dashboardStyle.Dashboard__Container}>
            {/* dashboard setting block */}
            <div className={dashboardStyle.Setting__Wrapper}>
                <div className={dashboardStyle.Setting__Date}>
                    <p className='h6 fc-gray-600'>날짜 설정</p>
                    <div className='spacing-8'/>
                    <DateRangePicker 
                        startDate={startDate} 
                        endDate={endDate} 
                        setStartDate={setStartDate} 
                        setEndDate={setEndDate} 
                    /> 
                </div>
                <div className='spacing-24'/>
                <div className={dashboardStyle.Setting__Channel}>
                    <p className='h6 fc-gray-600'>유입 채널</p>
                    <div className='spacing-8' />
                    <Dropdown 
                        options={channels} 
                        optionValues={channelValues} 
                        setOptionValues={setChannelValues} 
                        selectedChannels={selectedChannels} 
                        setSelectedChannels={setSelectedChannels} 
                    />
                </div>
            </div>
            <div className='spacing-24'/>
            {/* db line graph small block */}
            <div className={dashboardStyle.DBLineGraphS__Container}>
                <div className={dashboardStyle.DBLineGraphS__Wrap}>
                    <div className={dashboardStyle.DBLineGraphS__Body} style={{width: '342px'}}>
                        <SalesChart isLoading={isLoading} noData={noData[0]} data={dashboardData?.lineS} dbLineGraphSIdx={0} startDate={startDate} endDate={endDate}/>
                    </div>
                    <div className='spacing-8'/>
                    <div className={dashboardStyle.DBLineGraphS__Body}>
                        <UsersChart isLoading={isLoading} noData={noData[0]} data={dashboardData?.lineS} dbLineGraphSIdx={1} startDate={startDate} endDate={endDate}/>
                    </div>
                    <div className='spacing-8'/>
                    <div className={dashboardStyle.DBLineGraphS__Body}>
                        <ConversionRateChart isLoading={isLoading} noData={noData[0]} data={dashboardData?.lineS} dbLineGraphSIdx={2} startDate={startDate} endDate={endDate}/>
                    </div>
                </div>
                <div className={dashboardStyle.DBLineGraphS__Wrap}>
                    <div className={dashboardStyle.DBLineGraphS__Body}>
                        <ConversationChart isLoading={isLoading} noData={noData[0]} data={dashboardData?.lineS} dbLineGraphSIdx={3} startDate={startDate} endDate={endDate}/>
                    </div>
                    <div className='spacing-8'/>
                    <div className={dashboardStyle.DBLineGraphS__Body}>
                        <ARPPUChart isLoading={isLoading} noData={noData[0]} data={dashboardData?.lineS} dbLineGraphSIdx={4} startDate={startDate} endDate={endDate}/>
                    </div>
                </div>
            </div>
            <div className='spacing-24'/>
            {/* db line graph large block */}
            <div className={dashboardStyle.DBGraphL__Container}>
                <div className={dashboardStyle.DBGraphL__Header}>
                    <p className='h4 fc-gray-800'>구매, 장바구니 건수</p>
                </div>
                <div className={dashboardStyle.DBGraphL__TextContainer}>
                    {
                        dbLineGraphLTextContents.map((title, key) => (
                            <div key={key} className={dashboardStyle.DBGraphL__TextWrap}>
                                <p className='h6 fc-gray-600' style={{ width: '100%', textAlign: 'center' }}>{title}</p>
                                <div className='spacing-16'/>
                                {
                                    isLoading ? (
                                        <img src='/img/units/loading-ani-S.gif' width={105} height={30}/>
                                    ) : (
                                        <p className='h3 fc-gray-800' style={{ width: '100%', textAlign: 'center' }} >
                                            {
                                                noData[1] ? `No data` :
                                                    (dashboardData?.lineL[0].textDatas[key].unitPosition === 'prefix' 
                                                    ? `${dashboardData?.lineL[0].textDatas[key].unit}${getFormattedNumberValue(dashboardData?.lineL[0].textDatas[key].value)}` 
                                                    : `${getFormattedNumberValue(dashboardData?.lineL[0].textDatas[key].value)}${dashboardData?.lineL[0].textDatas[key].unit}` )
                                            }
                                        </p>
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
                <div className={dashboardStyle.DBGraphL__Body__line}>
                    <CartNPurchaseChart isLoading={isLoading} noData={noData[1]} data={dashboardData?.lineL} startDate={startDate} endDate={endDate} />
                </div>
                <div className={dashboardStyle.DBGraphL__Footer}>
                    <div className={dashboardStyle.DBGraphL__Legend}>
                        <div className={dashboardStyle.DBGraphL__Marker__cart}/>
                        <div className='spacing-8'/>
                        <p className='h7 fc-gray-700'>총 장바구니 담기 건수</p>
                    </div>
                    <div className='spacing-8'/>
                    <div className={dashboardStyle.DBGraphL__Legend}>
                        <div className={dashboardStyle.DBGraphL__Marker__purchase}/>
                        <div className='spacing-8'/>
                        <p className='h7 fc-gray-700'>총 구매 건수</p>
                    </div>
                </div>
            </div>
            <div className='spacing-24'/>
            {/* 퍼널 chart */}
            <div className={dashboardStyle.DBGraphL__Container}>
                <div className={dashboardStyle.DBGraphL__Header}>
                    <p className='h4 fc-gray-800'>상품 탐색 퍼널</p>
                </div>
                <div className={dashboardStyle.DBGraphL__TextContainer}>
                    {
                        dbBarGraphLTextContents.map((title, key) => (
                            <div key={key} className={dashboardStyle.DBGraphL__TextWrap}>
                                <p className='h6 fc-gray-600' style={{ width: '100%', textAlign: 'center' }}>{title}</p>
                                <div className='spacing-16'/>
                                {
                                    isLoading ? (
                                        <img src='/img/units/loading-ani-S.gif' width={105} height={30}/>
                                    ) : (
                                        <p className='h3 fc-gray-800' style={{ width: '100%', textAlign: 'center' }} >
                                            {
                                                noData[2] ? `No data` :
                                                    (dashboardData?.barL[0].textDatas[key].unitPosition === 'prefix' 
                                                    ? `${dashboardData?.barL[0].textDatas[key].unit}${getFormattedDecimalValue(dashboardData?.barL[0].textDatas[key].value, dashboardData?.barL[0].textDatas[key].decimalPlaces)}` 
                                                    : `${getFormattedDecimalValue(dashboardData?.barL[0].textDatas[key].value, dashboardData?.barL[0].textDatas[key].decimalPlaces)}${dashboardData?.barL[0].textDatas[key].unit}` )
                                            }
                                        </p>
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
                <div className={dashboardStyle.DBGraphL__Body__bar}>
                    <FunnelChart isLoading={isLoading} noData={noData[2]} data={dashboardData?.barL}/>
                </div>
            </div>
        </div>
    )
};

const fillMultiSeriesMissingDates = (multiSeries) => {
    if (!multiSeries) return [];

    const allDates = new Set();
    multiSeries.forEach(series => {
        series.data.forEach(entry => allDates.add(entry.x));
    });

    const sortedDates = Array.from(allDates).sort((a, b) => new Date(a) - new Date(b));

    const filledSeries = multiSeries.map(series => {
        const dataMap = new Map(series.data.map(entry => [entry.x, entry.y]));
        const filledData = sortedDates.map(x => ({
            x,
            y: dataMap.has(x) ? dataMap.get(x) : null
        }));
        return { ...series, data: filledData };
    });

    return filledSeries;
}



/* 이하 레거시 */

const KPICard = styled.div`
    width: 310px;
    height: 132px;
    background: ${colorSet.prim50};
    border-radius: 5px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.25);
`


const Board = styled.div`
    background-color: ${colors.base};
    margin: 8px;
    border-radius: 10px;
`

const GridBoard = styled(Board)`
    display: grid;
    grid-template-columns: ${props => props.$gridCol || ""};
    grid-template-rows: ${props => props.$gridRow || ""};
    grid-column-start: ${props => props.$gridColStart || ""};
    grid-column-end: ${props => props.$gridColEnd || ""};
`

const InfoPannel = styled.div`
    width: 100%;
    padding: 48px 40px;
`

const Content = styled.div`
    width: 100%;
    margin-bottom: 48px;

    h1 {
        font-size: 40px;
        font-weight: 900;

        span {
            font-size: 24px;
        }
    }
`

const FunnelTable = styled.table`
    width: calc(100% - 100px);
    height: 80px;
    margin-left: 80px;
    margin-right: 20px;
    text-align: center;
    font-size: 16px;
`

const SubBoard = styled.div`
    width: 100%;
    // background-color: green;
    // margin: 4px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const KPILabel = styled.p`
    font-size: ${props => props.$fontSize || "0.8rem"};
    font-weight: ${props => props.$fontWeight || 800};
    line-height: 1.5;
`

const InfoIcon = styled.img`
    width: 16px;
    margin: 0 4px;
`

const InfoModal = styled.span`
    position: absolute;
    width: ${props => props.$width || "140px"};
    padding: 8px;
    border-radius: 5px;
    background-color: ${colors.backDark};
    color: ${colors.fontGray};
    font-size: 12px;
    word-break: keep-all;
    z-index: 10;
`

const HeadIcon = styled.div`
    width: 40px;
    height: 40px;
    margin-top: 8px;
    background: url("/img/dashboard/trending_up_FILL0_wght700_GRAD0_opsz48.svg");
`

export default Dashboard
import customStyle from '../../style/units.module.css';
import { SampleContents } from '../../data/unitsData';
import { useEffect, useState } from 'react';
import { ChromePicker, SketchPicker } from 'react-color';
import { uploadImg } from '../../apis/services/create/utils';
import { CustomTooltip, CustomRefreshTooltip } from './CustomTooltip';
import { generateStartExample } from '../../apis/services/create/api';

export const LogoInput = (props) => {
    const {initLogo, updateSetting} = props;
    const checkFileSize = (e) => {
        const fileSize = e.target?.files[0]?.size;
        const sizeToMB = (fileSize / (1024 * 1024)).toFixed(1);

        if (sizeToMB > 2) {
            alert('2MB 이하 파일만 업로드 가능합니다');
        } else {
            uploadImg(e.target?.files[0], updateSetting);
        }
    }

    return (
        <div className={customStyle.CustomSet__Container}>
            <p style={{width: 'fit-content'}} className='h6 fc-gray-600'>프로필</p>
            <div className='spacing-8' />
            <div>
                <label htmlFor='profile' style={{position: 'relative'}}>
                    <img 
                        src='/img/units/preview-logo-selection.png' width={32} height={32}
                        style={{position: 'absolute', bottom: 2, left: 48, cursor: 'pointer'}}
                    />
                    {/* <img src='/img/units/preview-logoinput.png' width={80} height={80} /> */}
                    <img src={initLogo} width={80} height={80} style={{cursor: 'pointer'}}/>
                </label>
                <input 
                    type='file' id='profile' name='profile' accept='.png, .jpg' 
                    className='visually-hidden' 
                    onChange={(e) => checkFileSize(e)}
                />
                <div className='spacing-8' />
                <p className='h12 fc-gray-500' >png, jpg 형식, 2MB 이하 파일만 업로드 가능합니다</p>
            </div>
        </div>
    )
}

export const NameInput = (props) => {
    const {initName, updateSetting} = props;

    return (
        <div className={customStyle.CustomSet__Container}>
            <p style={{width: 'fit-content'}} className='h6 fc-gray-600'>에이전트 이름</p>
            <div className='spacing-8' />
            <div className={customStyle.CustomSet__TextInputWrap}>
                <input 
                    type='text' 
                    defaultValue={initName}
                    className={customStyle.CustomSet__TextInput}
                    onChange={(e) => updateSetting('name', e.target.value)}
                />
            </div>
        </div>
    )
}

export const ColorInput = (props) => {
    const {initColor, updateSetting, setCarouselVisible} = props;
    const [background, setBackground] = useState(initColor);
    const [openPicker, setOpenPicker] = useState([false, false, false]);
    const [selected, setSelected] = useState();

    const colorTooltipText = ["채팅창의 대표 색상으로 활용되며, 배경과 텍스트 등 다양한 요소에 사용됩니다. 가독성을 위해 명도가 높은 색상은 피하는 것을 권장합니다", "채팅창에서 보조색상으로 활용되며, 시작 예시 버튼의 hover 배경색으로 사용됩니다", "채팅창에서 보조색상으로 활용되며, 시작 예시 버튼의 배경색으로 사용됩니다"];
    const colorName = ["Primary", "Secondary", "Tertiary"]

    const handleChangeComplete = (e, idx) => {
        const newColor = {
            hex: e.hex,
            rgb: e.rgb,
            red: e.rgb.r,
            green: e.rgb.g,
            blue: e.rgb.b,
            alpha: e.rgb.a,
        }
        let newColorInput = initColor;
        newColorInput[idx] = newColor;

        updateSetting('colorCode', newColorInput);
    };

    const handleOpenPicker = (e, idx) => {
        // e?.stopPropagation();
        let copy = [...openPicker];
        copy = [false, false, false];
        copy[idx] = true;
        setSelected(idx);
        setOpenPicker(copy);
        setCarouselVisible(false);
    }

    const handleClosePicker = (e) => {
        e?.stopPropagation();
        setSelected();
        setOpenPicker([false, false, false]);
    }
    
    useEffect(() => {
        setBackground(initColor);
        setSelected(initColor);
    }, [initColor])

    return (
        <div className={customStyle.CustomSet__Container}>
            <p style={{width: 'fit-content'}} className='h6 fc-gray-600'>테마 색상</p>
            <div className='spacing-8' />
            <div className={customStyle.CustomSet__ColorInput__Wrap}>
                {
                    background?.map((data, idx) => {
                        return (
                            <div key={idx} style={{ display: 'flex', flexDirection: 'column', gap: '2px'}}>
                                <div className={`${selected === idx ? customStyle.CustomSet__ColorInput__active : customStyle.CustomSet__ColorInput} pointer`}  onClick={(e) => handleOpenPicker(e, idx)} >
                                    <div className={customStyle.CustomSet__ColorInput__Circle} style={{background: `rgba(${data.red}, ${data.green}, ${data.blue}, ${data.alpha})`}} />
                                    <img 
                                        src={`${selected === idx ? '/img/units/custom-expanded.png' : '/img/units/custom-expand.png'}`} width={24} height={24} 
                                        // onClick={(e) => handleOpenPicker(e, idx)} 
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start'}}>
                                    <p className='h12 fc-gray-500' style={{padding: '4px'}}>{colorName[idx]}</p>
                                    <CustomTooltip tipText={colorTooltipText[idx]}/>
                                </div>
                                {
                                    openPicker[idx] ?
                                    <div style={{position: 'relative'}}>
                                        <div style={{position: 'absolute', zIndex: 2}}>
                                            <div>
                                            <ChromePicker 
                                                color={ background[idx].rgb }
                                                onChange={ (e) => handleChangeComplete(e, idx) }
                                            />
                                            </div>
                                        </div>
                                        <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 1}} onClick={(e) => {handleClosePicker(e)}} />
                                    </div> : 
                                    null
                                }
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export const GreetingInput = (props) => {
    const {initGreeting, updateSetting} = props;

    return (
        <div className={customStyle.CustomSet__Container}>
            <p style={{width: 'fit-content'}} className='h6 fc-gray-600'>시작 문구</p>
            <div className='spacing-8' />
            <div className={customStyle.CustomSet__TextInputWrap}>
                <input 
                    type='text' 
                    defaultValue={initGreeting}
                    className={customStyle.CustomSet__TextInput}
                    onChange={(e) => updateSetting('greetingMessage', e.target.value)}
                />
            </div>
        </div>
    )
}

export const ExamplesInput = (props) => {
    const {initExamples, updateSetting, partnerId} = props;
    const [examples, setExamples] = useState(
        initExamples?.map((text, idx) => ({
            id: idx + 1,
            text,
            generating: false,
            error: false,
        })) || []
    );
    const generatingText = "문장을 조립하고 있습니다...";
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        const newExamples = initExamples?.map((text, idx) => ({
            id: idx + 1,
            text,
            generating: false,
            error: false,
        })) || [];

        setExamples((prevExamples) => {
            const isIdentical =
                prevExamples.length === newExamples.length &&
                prevExamples.every((ex, index) => ex.text === newExamples[index].text);
            
            return isIdentical ? prevExamples : newExamples;
        });
    }, [initExamples])

    useEffect(() => {
        if (examples && examples.length > 0) {
            const errorExamples = examples.filter(ex => ex.error);
            setIsError(errorExamples.length !== 0);
            updateSetting('examples', examples.map((ex) => ex.text));
        }
    }, [examples]);

    const handleAddExample = () => {
        if (examples.length < 7) {
            const newExample = {
                id: Date.now(), // unique ID
                text: generatingText,
                generating: true,
                error: false,
            };

            setExamples(prevExamples => [...prevExamples, newExample]);
            generateStartExample(partnerId, 1)
                .then(res => {
                    const newExampleText = res?.examples?.[0] || null;
                    setExamples((prev) =>
                        prev.map((ex) =>
                            ex.id === newExample.id
                                ? (
                                    newExampleText ?
                                    { ...ex, text: newExampleText, generating: false } :
                                    { ...ex, text: newExampleText, generating: false, error: true }
                                )
                                : ex
                        )
                    );             
                })
                .finally(() => {
                    setExamples((prev) =>
                        prev.map((ex) =>
                            ex.id === newExample.id ? { ...ex, generating: false } : ex
                        )
                    );
                })
        }
    }

    const handleGenerate = (id) => {
        setExamples((prev) =>
            prev.map((ex) =>
                ex.id === id
                    ? { ...ex, text: generatingText, generating: true }
                    : ex
            )
        );
        generateStartExample(partnerId, 1)
            .then(res => {
                const newExampleText = res?.examples?.[0] || null;
                setExamples((prev) => 
                    prev.map((ex) =>
                        ex.id === id
                        ? (
                            newExampleText ?
                            { ...ex, text: newExampleText, generating: false } : 
                            { ...ex, text: newExampleText, generating: false, error: true }
                        )
                        : ex
                    )
                );  
            })
            .finally(() => {
                setExamples((prev) =>
                    prev.map((ex) =>
                        ex.id === id ? { ...ex, generating: false } : ex
                    )
                );
            })
    }

    const handleDeleteExample = (id) => {
        if (examples?.length > 3) {
            setExamples(prevExamples => prevExamples.filter((ex) => ex.id !== id));
        }
    }

    return (
        <div className={customStyle.CustomSet__Container}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px'}}>
                <p style={{width: 'fit-content'}} className='h6 fc-gray-600'>시작 예시 버튼</p>
                <CustomTooltip tipText={"문구 변경 시 콘솔 ‘분석’ 탭 순위에 영향을 줄 수 있으며, 예시 버튼은 랜덤하게 3개만 표시됩니다."} />
            </div>
            <div className='spacing-8' />
            <div className={customStyle.CustomSet__ExInput__Wrap}>
                <div className={customStyle.CustomSet__ExInput__ExList}>
                    {
                        examples?.map((ex, idx) => {
                            return (
                                <div key={ex.id} className={customStyle.CustomSet__ExInput__ExWrap}>
                                    <div key={ex.id} className={`${ex.error ? customStyle.CustomSet__ExInput__ExBox__error : customStyle.CustomSet__ExInput__ExBox__default}`}>
                                        <div className={customStyle.CustomSet__ExInput__ExIndex}>
                                            <p className='h9 fc-gray-500'>
                                                {idx + 1}
                                            </p>
                                        </div>
                                        <div style={{display: 'flex', alignItems: 'center', flex: '1 0 0', cursor: 'default'}}>
                                            {
                                                ex.generating ?
                                                <p className='h7 fc-gray-400' style={{flex: '1 0 0', alignSelf: 'stretch', overflow: 'visible', whiteSpace: 'normal'}}>
                                                    {ex.text}
                                                </p> :
                                                <p className='h7 fc-gray-800' style={{flex: '1 0 0', alignSelf: 'stretch', overflow: 'visible', whiteSpace: 'normal'}}>
                                                    {ex.text}
                                                </p>   
                                            } 
                                        </div>
                                        {
                                            ex.generating ?
                                            <div onClick={() => handleGenerate(ex.id)}>
                                                <CustomRefreshTooltip tipText="예시 문구 재생성" isGenerating={true}/>
                                            </div> :
                                            <div onClick={() => handleGenerate(ex.id)} >
                                                <CustomRefreshTooltip tipText="예시 문구 재생성" isGenerating={false}/>
                                            </div>
                                        }
                                    </div>
                                    <div className='spacing-8'/>
                                    { examples?.length !== 3 ? 
                                        <div className={customStyle.CustomSet__ExInput__ExRemove} onClick={() => {handleDeleteExample(ex.id)}}>
                                            <img src='/img/units/close.png' width={12} height={12} alt='' style={{cursor: 'pointer'}}/> 
                                        </div>
                                        : 
                                        <div style={{ width: '28px'}}/>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                <div className='spacing-12' />
                <div className={customStyle.CustomSet__ExInput__AddBtnWrap}>
                    {
                        examples?.length < 7 ?
                        <div className={customStyle.CustomSet__ExInput__AddBtn__default} onClick={handleAddExample}>
                            <p className='h7 fc-gray-800'>항목 추가</p>
                        </div> :
                        <div className={customStyle.CustomSet__ExInput__AddBtn__disabled}>
                            <p className='h7 fc-gray-500'>항목 추가</p>
                        </div>
                    }
                </div>
                <div className='spacing-12' />
                {
                    isError ?
                    <div className={customStyle.CustomSet__ExInput__ErrorWrap}>
                        <p className='h13 fc-warning'>• 생성에 실패했습니다. 다시 시도해 주세요.</p>
                    </div> : null
                }
            </div>
        </div>
    )
}

export const CarouselTypeInput = (props) => {
    const {initCarouselType, updateSetting, setCarouselVisible} = props;
    const [selected, setSelected] = useState(initCarouselType);

    const handleClickInput = (e) => {
        e.stopPropagation();
        const value = e.target.value;
        updateSetting('carouselType', value);
        setSelected(value);
        setCarouselVisible(true);
    }

    useEffect(() => {
        setSelected(initCarouselType);
    }, [initCarouselType])

    return (
        <div className={customStyle.CustomSet__Container}>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px'}}>
                <p style={{width: 'fit-content'}} className='h6 fc-gray-600'>상품 표시 유형</p>
                <CustomTooltip tipText={"고객이 상품 추천을 요청했을 때 사용되는 답변 형태입니다"} />
            </div>
            <div className='spacing-8' />
            <div className={customStyle.CustomSet__CrsTypeWrap}>
                <div style={{textAlign: 'center'}}>
                    <label htmlFor='single'>
                        <div 
                            className={`${customStyle.CustomSet__CrsType__single} ${selected === 'single' && customStyle.CustomSet__CrsType__activate}`}
                            // style={{border: '2px solid #bbb'}}
                        >
                            <img src='/img/units/custom-carousel-single.png' width={114} />
                        </div>
                    </label>
                    <input 
                        type='radio' id='single' name='carousel-type' value='single'
                        className='visually-hidden'
                        // disabled={true}
                        onClick={(e) => {handleClickInput(e)}}
                    />
                    <div className='spacing-8' />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px'}}>
                        <p className={`h7 ${selected === 'single' ? 'fc-prim-800' : 'fc-gray-600'}`}>단독형</p>
                        <CustomTooltip tipText={"상품 N개를 추천하며, 한 화면에 1개만 표시해 집중도를 높입니다."} />
                    </div>
                </div>
                <div style={{textAlign: 'center'}}>
                    <label htmlFor='carousel-horizontal'>
                        <div className={`${customStyle.CustomSet__CrsType__horizontal} ${selected === 'carousel-horizontal' && customStyle.CustomSet__CrsType__activate}`}>
                            <img src='/img/units/custom-carousel-horizontal.png' width={117} />
                        </div>
                    </label>
                    <input 
                        type='radio' id='carousel-horizontal' name='carousel-type' value='carousel-horizontal'
                        className='visually-hidden'
                        //disabled={true}
                        onClick={(e) => {handleClickInput(e)}}
                    />
                    <div className='spacing-8' />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px'}}>
                        <p className={`h7 ${selected === 'carousel-horizontal' ? 'fc-prim-800' : 'fc-gray-600'}`}>가로형</p>
                        <CustomTooltip tipText={"상품 N개를 추천하며, 화면에는 한 번에 3개씩 목록 형태로 표시합니다. 다양한 상품을 한눈에 볼 수 있습니다"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

/* export const CarouselDirInput = (props) => {
    const {initImageRatio, updateSetting} = props;
    const [selected, setSelected] = useState(initImageRatio);

    const handleClickRadio = (e) => {
        updateSetting('imageRatio', e.target.id);
        setSelected(e.target.id);
    }

    useEffect(() => {
        setSelected(initImageRatio)
    }, [initImageRatio])

    return (
        <div className={customStyle.CustomSet__Container}>
            <p style={{width: 'fit-content'}} className='h6 fc-gray-600'>이미지 비율</p>
            <div className='spacing-8' />
            <div className={customStyle.CustomSet__RadioWrapVert}>
                <div style={{display: 'flex'}}>
                    <input 
                        type='radio'
                        id='carousel-vertical' 
                        disabled={true}
                        checked={selected === 'carousel-vertical' ? true : false}
                        onChange={(e) => handleClickRadio(e)}
                    />
                    <div className='spacing-16' />
                    <label htmlFor='carousel-vertical' className={`h7 ${selected === 'carousel-vertical' ? 'fc-gray-600' : 'fc-gray-600'}`}>세로형</label>
                </div>
                <div style={{display: 'flex'}}>
                    <input 
                        type='radio'
                        id='carousel-horizontal' 
                        disabled={true}
                        checked={selected === 'carousel-horizontal' ? true : false}
                        onChange={(e) => handleClickRadio(e)}
                    />
                    <div className='spacing-16' />
                    <label htmlFor='carousel-horizontal' className={`h7 ${selected === 'carousel-horizontal' ? 'fc-prim-800' : 'fc-gray-600'}`}>가로형</label>
                </div>
            </div>
        </div>
    )
} */

export const ExceptKeyword = (props) => {
    const {initKeywords, updateSetting} = props;
    const [isInputFocused, setisInputFocused] = useState(false);
    const [input, setInput] = useState('');
    const [inputArr, setInputArr] = useState(initKeywords);

    const handleFocusInput = () => {
        setisInputFocused(true);
    }

    const handleBlurInput = () => {
        if (input.length === 0) setisInputFocused(false);
    }

    const handleChangeInput = (e) => {
        setInput(e.target.value);
    }

    const handleClickAdd = (e) => {
        if (input.length === 0) return;
        let arr = [...inputArr];
        arr.push(input);
        setInput('');
        setInputArr(arr);
        updateSetting('exceptKeyword', arr);
        setisInputFocused(false);
    }

    const handleClickDelete = (idx) => {
        let arr = [...inputArr];
        arr.splice(idx, 1);
        setInputArr(arr);
        updateSetting('exceptKeyword', arr);
    }

    return (
        <div className={customStyle.CustomSet__Container}>
            <p style={{width: '56px'}} className='h6 fc-gray-600'>제외 키워드</p>
            <div className='spacing-32' />
            <div style={{width: '100%'}}>
                <div className={customStyle.CustomSet__ExKeywords__TextInputWrap} style={{border: isInputFocused && `1px solid #154cca`}}>
                    <input 
                        type='text' 
                        placeholder=''
                        className={customStyle.CustomSet__ExKeywords__TextInput}
                        onFocus={() => handleFocusInput()}
                        onBlur={() => handleBlurInput()}
                        onChange={(e) => handleChangeInput(e)}
                        value={input}
                    />
                    {
                        isInputFocused &&
                        <img 
                            src='/img/units/custom-add.png' width={24} height={24}
                            onClick={(e) => handleClickAdd(e)}
                        />
                    }
                </div>
                <div className='spacing-8' />
                <div className={customStyle.CustomSet__ExKeywords__KeywordsWrap}>
                    {
                        inputArr.map((keyword, idx) => {
                            return (
                                    <div key={idx} className={customStyle.CustomSet__ExKeywords__Keyword}>
                                        <p className='h7 fc-gray-700'>{keyword}</p>
                                        <img 
                                            src='/img/units/toast-close.png' width={24} height={24} 
                                            onClick={() => handleClickDelete(idx)}
                                        />
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </div>
        </div>
    )
}



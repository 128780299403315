import styled from 'styled-components';
import { colors } from '../style/colorVar';

const Prep = (props) => {
    const { smallText, betaBtn, betaBtnLink } = props;
    return (
        <Container>
            <FlexWrap>
                <img src='/img/units/prep-img.png' width={250} height={182} />
                <div className='spacing-20' />
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', alignSelf: 'stretch', cursor: 'default'}}>
                    <p className='h3 fc-gray-700'>현재 페이지 준비중입니다</p>
                    <div className='spacing-8' />
                    <p className='h7 fc-gray-700' 
                        style={{width: '250px', textAlign: 'center', whiteSpace: 'pre-line'}}
                    >
                        {smallText}
                    </p>
                </div>
                <div className='spacing-20' />
                {betaBtn && <a href={betaBtnLink} target='_blank' rel='noopener noreferrer'>
                    <div style={{ display: 'flex', width: 'fit-content', height: '50px', padding: '12px 20px', alignItems: 'center', borderRadius: '10px', background: '#222222', cursor: 'pointer'}}>
                        <p className='h4 fc-white'>기능 살펴보기</p>
                    </div>
                </a>}
            </FlexWrap>
        </Container>
    )
};

const Container = styled.div`
    width: 100%;
    height: 100%;
    // background-color: ${colors.base};
    border-radius: 10px;
`

const FlexWrap = styled.div`
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
`

const Contents = styled.span`
    font-size: 2em;
    font-weight: 800;
    color: ${colors.fontGray};
`

export default Prep
import { useSearchParams } from "react-router-dom";

const DeployPreview = () => {
    const [searchParams] = useSearchParams();
    const posX = searchParams.get('posX') || 0;
    const posY = searchParams.get('posY') || 66;

    return (
        <div style={{ width: '100%', height: '100%', overflowY: 'scroll'}}>
            <img src='/img/deployPreview/background.svg' style={{width: '100%', height: 'auto'}} />
            <img 
                src='/img/deployPreview/gentoo-floating-preview.gif' 
                style={{width: '380px', height: '80px', position: 'absolute', bottom: posY + 'px', right: posX + 'px', zIndex: 5}} 
            />
        </div>
    )
}

export default DeployPreview;
import axios from "axios";
import { version } from "react";

export const signup = async (inputList, mallId = '', shopUserId = '') => {
    let payload = {};
    for (const [key, val] of Object.entries(inputList)) {
        payload[val.nameInput] = val.value;
    }

    try {   
        const versionResponse = await axios.get(
            process.env.REACT_APP_TOS_VERSION_URL,
            {
                params: {},
                headers: {}
            }
        )
        const versionData = versionResponse.data

        const response = await axios.post(
            process.env.REACT_APP_SIGNUP_URL,
            {
                email: payload.email,
                password: payload.password,
                partnerCompanyName: payload.partnerCompanyName,
                mallUrl: payload.mallUrl,
                manager: payload.manager,
                phoneNumber: payload.phoneNumber,
                tosVersion: versionData.version,
                tosAgree: true,
                cafe24MallId: mallId,
                cafe24UserId: shopUserId,
                cafe24DefaultShopNo: 1,
                
            },{
                headers: {}
            }
        )
        return response.data;
    } catch (error) {
        console.error(`Error while calling signup api: ${error}`)
    }
}

export const login = async (email, password) => {
    // temp code before GENT-968
    if (email === 'selentest@email.com') {
        return {
            randomId: 'selentest',
            clientId: 'test',
            accessLevel: 4,
            chatbotList: [
                {
                    chatbotName: 'test',
                    chatbotId: 1,
                }
            ]
        }
    } else {
        // og code part for login
        try {
            const response = await axios.post(
                process.env.REACT_APP_AUTH_URL,
                {
                    email: email,
                    password: password
                },{
                    headers: {}
                }
            )
            return response.data
        } catch (error) {
            console.error(`Error while calling login api: ${error}`)
            throw error.response.data.message
        }
    }
}

export const getAccountInfo = async (partnerId) => {
    try {
        const response = await axios.get(
            process.env.REACT_APP_ACCOUNTINFO_URL + `/${partnerId}`,
            {
                params: {},
                headers: {}
            }
        )
        return response.data
    } catch (error) {
        console.error(`Error while calling getAccountInfo api: ${error}`);
    }
}

export const postScript = async (partnerId, chatbotId) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_SCRIPTTAG_URL + `/${partnerId}`,
            {
                shopNo: 1,
                src: `https://d32xcphivq9687.cloudfront.net/sdk-cafe24.js`,
                displayLocation: [
                  "MAIN",
                  "PRODUCT_LIST",
                  "PRODUCT_DETAIL",
                ],
                excludePath: [],
            },{
                headers: {}
            }
        )
    } catch (error) {
        console.error(`Error while calling postScriptTest api: ${error}`);
    }
}

export const getScript = async (partnerId) => {
    try {
        const response = await axios.get(
            process.env.REACT_APP_SCRIPTTAG_URL + `/${partnerId}/list`,
            {
                params: {
                    partnerId: partnerId,
                },
                headers: {}
            }
            
        )
    } catch (error) {
        console.error(`Error while calling postScriptTest api: ${error}`);
    }
}

export const getAccessCode = async (email) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_FETCH_ACCESSCODE_URL,
            {
                email: email,
            }, {
                headers: {}
            }
        )
    } catch (error) {
        console.error(`Error while calling getAccessCode API: ${error}`);
    }
}

export const postAccessCode = async (email, accessCode) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_VERIFY_ACCESSCODE_URL,
            {
                email: email,
                authCode: accessCode,
            }, {
                headers: {}
            }
        )
        return response.status;
    } catch (error) {
        console.error(`Error while calling postAccessCode API: ${error}`);
        throw error.response.data.message;
    }
}

export const postNewPassword = async (email, accessCode, password) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_RESET_PASSWORD_URL,
            {
                email: email,
                authCode: accessCode,
                password: password,
            }, {
                headers: {}
            }
        )
        return response.status;
    } catch (error) {
        console.error(`Error while calling postNewPassword API: ${error}`);
        throw error.response.data.message;
    }
}

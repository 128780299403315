import { useNavigate } from "react-router-dom"
import { DefaultButton } from "../components/Units/Button"
import { InputField } from "../components/Units/InputField"
import { useEffect, useState } from "react";
import { getAccessCode, postAccessCode, postNewPassword } from "../apis/api/auth/api";
import style from '../style/passwordReset.module.css';
import { ErrorText } from "../components/Units/Alerts";

const PasswordReset = () => {
    const [step, setStep] = useState(1);    // 1: 이메일인증, 2: 인증코드 입력, 3: 비밀번호 재설정
    const [email, setEmail] = useState();

    const handleNextStep = () => {
        setStep(prev => prev+1);
    }

    return (
        <div className={style.PasswordReset__Container}>
            <div className={style.PasswordReset__Wrap}>
                <TopToggle step={step} />
                <div className="spacing-24" />
                <Content step={step} email={email} />
                <div className="spacing-24" />
                <InputSection handleNextStep={handleNextStep} step={step} email={email} setEmail={setEmail} />
            </div>
        </div>
    )
}

const TopToggle = (props) => {
    const {step} = props;
    return (
        <div className={style.PasswordReset__TopWrap}>
            <div className={style.PasswordReset__Toggle__textBox}>
                <div className={step < 3 ? style.PasswordReset__Toggle__index : style.PasswordReset__Toggle__index__inactive}>
                    <p className={`h9 ${step < 3 ? 'fc-prim-800' : 'fc-gray-400'}`}>1</p>
                </div>
                <div className="spacing-4" />
                <p className={`h9 ${step < 3 ? 'fc-prim-800' : 'fc-gray-400'}`}>이메일인증</p>
            </div>
            <div className="spacing-8" />
            <div className={style.PasswordReset__Toggle__imageBox}>
                <img src="/img/units/arrow-forward-g300.png" width={16} height={16} />
            </div>
            <div className="spacing-8" />
            <div className={style.PasswordReset__Toggle__textBox}>
                <div className={step === 3 ? style.PasswordReset__Toggle__index : style.PasswordReset__Toggle__index__inactive}>
                    <p className={`h9 ${step === 3 ? 'fc-prim-800' : 'fc-gray-400'}`}>2</p>
                </div>
                <div className="spacing-4" />
                <p className={`h9 ${step === 3 ? 'fc-prim-800' : 'fc-gray-400'}`}>비밀번호 재설정</p>
            </div>
        </div>
    )
}

const Content = (props) => {
    const {step, email} = props;
    if (step === 1) {
        return (
            <div className={style.PasswordReset__Content}>
                <img src="/img/units/gentoo-symbol.png" width={32} height={32} />
                <div className="spacing-24" />
                <p className="h4 fc-gray-700">비밀번호 재설정</p>
                <div className="spacing-8" />
                <p className="h7 fc-gray-600">가입하신 이메일로 인증코드를 보내드립니다</p>
            </div>
        )
    } else if (step === 2) {
        return (
            <div className={style.PasswordReset__Content}>
                <img src="/img/units/gentoo-symbol.png" width={32} height={32} />
                <div className="spacing-24" />
                <p className="h4 fc-gray-700">비밀번호 재설정</p>
                <div className="spacing-8" />
                <p className="h7 fc-gray-600">{`${email}로 인증코드가 전송되었습니다`}</p>
            </div>
        )        
    } else {
        return (
            <div className={style.PasswordReset__Content}>
                <img src="/img/units/gentoo-symbol.png" width={32} height={32} />
                <div className="spacing-24" />
                <p className="h4 fc-gray-700">비밀번호 재설정</p>
            </div>
        )
    }
}

const InputSection = (props) => {
    const {handleNextStep, step, email, setEmail} = props;
    const navigate = useNavigate();
    const [valState, setValState] = useState(false);
    const [accessCode, setAccessCode] = useState('');
    const [password, setPassword] = useState('');
    const [timeoutState, setTimeoutState] = useState(false);
    const [reset, setReset] = useState(0);

    const handleRedirect = (destination) => {
        navigate(destination);
    }

    const handleValState = (bool) => {
        setValState(bool);
    }

    if (step === 1) {
        return (
            <div style={{width: '100%', height: 'fit-content', display: 'flex', flexDirection: 'column'}}>
                <InputField name={'email'} type={'email'} labelText={'이메일(ID)'} placeholderText={'이메일을 입력해 주세요'} callbackFunc={handleValState} storeFunc={setEmail} />
                <div className="spacing-24" />
                <DefaultButton 
                    buttonText={'인증메일 보내기'} disabled={!valState} 
                    onClick={() => {
                        getAccessCode(email)
                            .then(handleNextStep)
                    }}
                />
                <div className="spacing-16" />
                <p 
                    style={{width: '100', textAlign: 'center', cursor: 'pointer'}} 
                    className="h6 fc-prim-800"
                    onClick={() => handleRedirect('/login')}
                >로그인 페이지로 돌아가기</p>
            </div>
        )
    } else if (step === 2) {
        return (
            <div style={{width: '100%', height: 'fit-content', display: 'flex', flexDirection: 'column'}}>
                <InputField name={'accessCode'} type={'text'} labelText={'인증코드'} placeholderText={'인증코드를 입력해 주세요'} callbackFunc={handleValState} storeFunc={setAccessCode} />
                <div className="spacing-8" />
                <div style={{width: '100%', height: '60px', padding: '12px 16px', border: '1px solid #e1e1e1', borderRadius: '10px'}}>
                    <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <div style={{width:'fit-content', display: 'flex', alignItems: 'center'}}>
                            <img src="/img/units/clock.png" width={20} height={20} />
                            <div className="spacing-4" />
                            <p className="h8 fc-gray-600">남은 시간</p>
                            <div className="spacing-4" />
                            <FiveMinuteTimer setTimeoutState={setTimeoutState} period={300} reset={reset}/>
                        </div>
                        <button 
                            style={{width: '119px', height: '35px', background: '#f6f6f6', border: 'none', borderRadius: '6px'}}
                            onClick={() => {
                                getAccessCode(email)
                                    .then(res => setReset(prev => prev + 1))
                                    .catch(error => console.error('postaccesscode error, ', error));
                            }}
                        >
                            <p className="h7 fc-gray-600">인증코드 재전송</p>
                        </button>
                    </div>
                </div>
                {
                    timeoutState &&
                    <>
                        <div className="spacing-4" />
                        <ErrorText text={'인증번호를 다시 받아주세요'} />
                    </>
                }
                <div className="spacing-24" />
                <DefaultButton 
                    buttonText={'다음으로'} disabled={!valState} 
                    onClick={() => {
                        postAccessCode(email, accessCode)
                            .then(res => handleNextStep())
                            .catch(error => console.error('postaccesscode error, ', error));
                    }}
                />
                <div className="spacing-16" />
                <p 
                    style={{width: '100', textAlign: 'center', cursor: 'pointer'}} 
                    className="h6 fc-prim-800"
                    onClick={() => handleRedirect('/login')}
                >로그인 페이지로 돌아가기</p>
            </div>
        )
    } else {
        return (
            <div style={{width: '100%', height: 'fit-content', display: 'flex', flexDirection: 'column'}}>
                <InputField name={'password'} type={'password'} labelText={'새 비밀번호'} placeholderText={'8~16자 이내 영문, 숫자, 특수문자 사용 가능'} callbackFunc={handleValState} storeFunc={setPassword} />
                <div className="spacing-20" />
                <InputField name={'passwordConfirm'} type={'password'} labelText={'새 비밀번호 확인'} placeholderText={'새 비밀번호를 재입력 해주세요'} callbackFunc={handleValState} password={password} />
                <div className="spacing-24" />
                <DefaultButton
                    buttonText={'확인'} disabled={!valState}
                    onClick={() => {
                        postNewPassword(email, accessCode, password)
                            .then(res => handleRedirect('/login'))
                            .catch(error => console.error('postNewPassword error, ', error));
                    }}
                />
            </div>
        )
    }
}

const FiveMinuteTimer = (props) => {
    const {setTimeoutState, period, reset} = props;
    // 타이머를 초 단위로 관리 (5분 = 300초)
    const [timeLeft, setTimeLeft] = useState(period);
    useEffect(() => {
        setTimeLeft(period);
        setTimeoutState(false);
    }, [reset])
  
    useEffect(() => {
      // 타이머가 0 이하일 경우 타이머를 중지
      if (timeLeft <= 0) {
        setTimeoutState(true)
        return;
    }
  
      // 1초마다 실행되는 인터벌 설정
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalId); // 타이머 중지
            return 0;
          }
          return prevTime - 1; // 타이머 감소
        });
      }, 1000);
  
      // 컴포넌트 언마운트 시 인터벌 클리어
      return () => clearInterval(intervalId);
    }, [timeLeft]);
  
    /**
     * 초를 'm:ss' 형식으로 포맷팅하는 함수
     * @param {number} seconds - 남은 시간 (초)
     * @returns {string} - 포맷된 시간 문자열
     */
    const formatTime = (seconds) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      // 초가 10 미만일 경우 앞에 0을 추가
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
  
    return (
      <p className="h8 fc-warning">{formatTime(timeLeft)}</p>
    );
};

export default PasswordReset
import ApexCharts from "apexcharts";

export const debug_DBLineGraphSOption = {
    chart: {
        id: 'sales-chart',
        type: 'area',
        height: 100,
        width: '100%',
        sparkline: {
          enabled: true
        },
        zoom: {
            enabled: false,
        },
        toolbar: {
            show: false
        },
        parentHeightOffset: 0,
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
        animations: {
            enabled: true,
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
        events: {
            mounted: (chart) => {
                chart.windowResizeHandler();
            }
            /* mounted: (chart) => {
                setTimeout(() => {
                    console.log("mounted: ", chart);
                    chart.windowResizeHandler();
                    chart.parentResizeHandler();
                    chart.w.config.chart.width = '100%'
                  },800);
            },
            updated: (chart) => {
                setTimeout(() => {
                    console.log("updated: ", chart);
                    //chart.windowResizeHandler();
                    chart.w.config.chart.width = '100%'
                  },800);
            }, */
        }
    },
    dataLabels: {
        enabled: false,
    },
    fill: {
        type: 'gradient',
        gradient: {
            type: 'vertical',
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0.5,
            inverseColor: false,
            stops: [0, 100]
        }
    },
    xaxis: {
        tickAmount: 0,
        labels: {
            show: false
        },
        axisBorder: {
            show: false,
            color: '#E1E1E1',
            height: 2,
            width: '100%',
            offsetY: 0
        },
        axisTicks: {
            show: false
        },
        crosshairs: {
            show: false,
            width: 1,
            stroke: {
                color: '#999',
                width: 1,
                dashArray: 0,
            }
        },
    },
    yaxis: {
        show: false,
        //min: function (min) { return min },
        //max: function (max) { return max },
    },
    stroke: {
        width: 2,
        curve: 'straight'
    },
    grid: {
        show: false,
        padding: {
            top: 8,
            right: 0,
            bottom: 0,
            left: 0
        },
    },
}

export const DBLineGraphSOption = {
    chart: {
        type: 'area',
        height: 100,
        width: '100%',
        sparkline: {
          enabled: true
        },
        zoom: {
            enabled: false,
        },
        toolbar: {
            show: false
        },
        parentHeightOffset: 0,
        redrawOnWindowResize: true,
        redrawOnParentResize: true,
        animations: {
            enabled: true,
            speed: 800,
            animateGradually: {
                enabled: true,
                delay: 150
            },
            dynamicAnimation: {
                enabled: true,
                speed: 350
            }
        },
        events: {
            mounted: (chart) => {
                chart.windowResizeHandler();
            }
        }
    },
    dataLabels: {
        enabled: false,
    },
    fill: {
        type: 'gradient',
        gradient: {
            type: 'vertical',
            shadeIntensity: 1,
            opacityFrom: 0.5,
            opacityTo: 0.5,
            inverseColor: false,
            stops: [0, 100]
        }
    },
    xaxis: {
        tickAmount: 0,
        labels: {
            show: false
        },
        axisBorder: {
            show: false,
            color: '#E1E1E1',
            height: 2,
            width: '100%',
            offsetY: 0
        },
        axisTicks: {
            show: false
        },
        crosshairs: {
            show: true,
            width: 1,
            stroke: {
                color: '#999',
                width: 1,
                dashArray: 0,
            }
        },
        tooltip: {
            enabled: false
        }
    },
    yaxis: {
        show: false,
        min: function (min) { return min },
        max: function (max) { return max },
    },
    stroke: {
        width: 2,
        curve: 'straight'
    },
    grid: {
        show: false,
        padding: {
            top: 8,
            right: 0,
            bottom: 0,
            left: 0
        },
    },
}

export const DBLineGraphLOption = {
    chart: {
        type: 'line',
        height: 320,
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false
        }
    },
    colors: ['#BBC8E5', '#154CCA'],
    grid: {
        show: true,
        borderColor: '#E1E1E1',
        strokeDashArray: 5,
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 16,
        }
    },
    xaxis: {
        tickAmount: 0,
        labels: {
            show: false
        },
        axisBorder: {
            show: true,
            color: '#222222',
            height: 1,
            width: '100%',
            offsetY: 0,
        },
        axisTicks: {
            show: false
        },
        tooltip: {
            enabled: false,
        },
        crosshairs: {
            show: true,
            width: 1,
            stroke: {
                color: '#999',
                width: 1,
                dashArray: 0,
            }
        }
    },
    yaxis: {
        min: 0,
        show: true,
        tickAmount: 3,
        forceNiceScale: true,
        labels: {
            style: {
                colors: ['#444'],
                fontSize: '14px',
                fontFamily: 'Pretentard, Arial, sans-serif',
                fontWeight: 400,
            }
        },
    },
    stroke: {
        width: 2,
        curve: 'straight'
    },
    legend: {
        show: false,
        horizontalAlign: 'left',
    }
}

export const DBBarGraphLOption = {
    chart: {
        type: 'bar',
        height: 480,
        toolbar: {
            show: false
        },
        zoom: {
            enabled: false
        },
        events: {
            updated: function(chartContext, config) {
                const svg = chartContext.el.querySelector('svg');
                const gridBorders = svg.querySelector('.apexcharts-grid-borders');
                const barSeries = svg.querySelector('.apexcharts-bar-series.apexcharts-plot-series');
                
                if (gridBorders && barSeries) {
                    gridBorders.parentNode.insertBefore(gridBorders, barSeries.nextSibling);
                }
            }
        }
    },
    colors: ['#EFEFEF'],
    grid: {
        show: true,
        borderColor: '#E1E1E1',
        strokeDashArray: 5,
        padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 16,
        },
        position: 'back',
    },
    plotOptions: {
        bar: {
            borderRadius: 4,
            dataLabels: {
                position: 'top', // top, center, bottom
            },
            borderRadiusApplication: 'end',
            columnWidth: '60%',
        }
    },
    dataLabels: {
        enabled: true,
        formatter: function (val) {
            if (val >= 1_000_000) {
                return `${(val / 1_000_000).toFixed(2)}M`;
            } else {
                return `${(val / 1_000).toFixed(2)}k`;
            }
        },
        offsetY: -22,
        style: {
            fontSize: '12px',
            fontWeight: 600,
            fontFamily: 'Pretendard, Arial, sans-serif',
            colors: ["#999"]
        }
    },
    xaxis: {
        categories: ["노출", "유입", "클릭", "장바구니 담기", "구매"],
        position: 'bottom',
        axisBorder: {
            show: true,
            color: '#222222',
            height: 1,
            width: '100%',
            offsetY: 0,
            zIndex: 999,
        },
        axisTicks: {
            show: false
        },
        crosshairs: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
        labels: {
            style: {
                colors: ['#444'],
                fontSize: '14px',
                fontFamily: 'Pretentard, Arial, sans-serif',
                fontWeight: 400,
            }
        }
    },
    yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
            show: true,
            formatter: function (val) {
                if (val >= 1_000_000) {
                    return `${(val / 1_000_000).toFixed(0)}M`;
                } else if (val >= 1_000) {
                    return `${(val / 1_000).toFixed(0)}k`;
                } else {
                    return `${(val / 1_000).toFixed(2)}k`;
                }
            },
            style: {
                colors: ['#444'],
                fontSize: '14px',
                fontFamily: 'Pretentard, Arial, sans-serif',
                fontWeight: 400,
            },
        },
        logarithmic: true,
        logBase: 10,
        min: function(min) { return min },
        forceNiceScale: true,
    },
    states: {
        hover: {
            filter: {
                type: 'none'
            },
        },
        active: {
            filter: {
                type: 'none'
            }
        }
    },
}
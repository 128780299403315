import { useState } from 'react';
import { SampleContents } from '../../data/unitsData';
import previewStyle from '../../style/units.module.css';
import { Carousel } from './Carousel';

export const Preview = (props) => {
    const {initSettings, carouselVisible} = props;
    const [isHovered, setIsHovered] = useState([false, false, false]);

    const handleMouseOver = (idx) => {
        setIsHovered((prev) => {
            let newData = [...prev];
            newData[idx] = true;

            return newData
        });
    }

    const handleMouseLeave = () => {
        setIsHovered([false, false, false]);
    }

    return (
        <div className={previewStyle.Preview__Container}>
            <div className={previewStyle.Preview__Wrap}>
                <div className={previewStyle.Preview__Header} />
                <div className={previewStyle.Preview__Display}>
                    <div className={previewStyle.Preview__Display__Contents}>
                        {
                            carouselVisible ?
                            <Carousel type={initSettings?.carouselType} /> :
                            <img src='/img/display/gentoo-onboarding.gif' width={320} height={142} />
                        }
                    </div>
                    <div className={previewStyle.Preview__Display__Bottom}>
                        {
                            carouselVisible &&
                            <img 
                                src='/img/units/carousel-indicator.png' 
                                width={66} 
                                height={6} 
                                className={previewStyle.Preview__Display__Indicator}
                            />
                        }
                        <img 
                            src='/img/display/display-close.png' 
                            width={20} 
                            height={20} 
                            className={previewStyle.Preview__Display__Toggle}
                        />
                    </div>
                </div>
                <div className={previewStyle.Preview__Contents}>
                    <div className={previewStyle.Preview__Greeting}>
                        <img src={initSettings?.profileImg} width={32} height={32}/>
                        <div className='spacing-8' />
                        <div>
                            <p className='h13 fc-gray-500'>{initSettings?.name}</p>
                            <div className='spacing-4' />
                            <div 
                                className={previewStyle.Preview__Bubble__bot}>
                                    {
                                        carouselVisible ?
                                        <p className='h7 fc-gray-800'>A,B,C 제품은 눈 건강을 위한 영양제로  
                                        <b>루테인</b>과 <b>아스타잔틴</b>, 그리고 <b>헤마토코쿠스 추출물</b>이 함유되어 있습니다. 해당 성분들은
                                        시력 유지에 도움을 주고 눈의 피로도 개선에도
                                        효과적이라 추천드려요! 💪</p> :
                                        <p className='h7 fc-gray-800'>{initSettings?.greetingMessage}</p>
                                    }
                            </div>
                        </div>
                    </div>
                    {
                        carouselVisible ?
                        <div className='spacing-4' /> :
                        <div className='spacing-16' />
                    }
                    <div className={previewStyle.Preview__ExampleWrap}>
                        {
                            carouselVisible ?
                            <div style={{display: 'flex'}}>
                                <div className='spacing-40' />
                                <div className={previewStyle.Preview__Bubble__bot}>
                                    <p className='h7 fc-gray-800'><b>A,B,C 제품</b>에 관해 더 자세한 설명이 필요하신가요?</p>
                                </div>
                            </div> :
                            <div className={previewStyle.Preview__ExampleWrap}>
                                {
                                    initSettings?.examples?.slice(0, 3).map((exam, idx) => {
                                        return (
                                            <div 
                                                key={idx} className={previewStyle.Preview__Bubble__user}
                                                style={{background: isHovered[idx] ? 
                                                    `rgba(${initSettings?.colorCode[1].red}, ${initSettings?.colorCode[1].green}, ${initSettings?.colorCode[1].blue}, ${initSettings?.colorCode[1].alpha})` :
                                                    `rgba(${initSettings?.colorCode[2].red}, ${initSettings?.colorCode[2].green}, ${initSettings?.colorCode[2].blue}, ${initSettings?.colorCode[2].alpha})`}}
                                                onMouseEnter={() => handleMouseOver(idx)}
                                                onMouseLeave={() => handleMouseLeave()}
                                            >
                                                <p 
                                                    className='h7 fc-prim-800' 
                                                    style={{color: `rgba(${initSettings?.colorCode[0].red}, ${initSettings?.colorCode[0].green}, ${initSettings?.colorCode[0].blue}, ${initSettings?.colorCode[0].alpha})`}}
                                                >
                                                    {exam}
                                                </p>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        }
                    </div>
                </div>
                <div className={previewStyle.Preview__Bottom}>
                    <div className={previewStyle.Preview__InputWrap}>
                        <img src='/img/units/preview-reset.png' width={36} height={36} />
                        <div className='spacing-8' />
                        <div className={previewStyle.Preview__Input}>
                            <p className='h7 fc-gray-500'>무엇이든 물어보세요</p>
                            <img src='/img/units/preview-send.png' width={24} height={24}/>
                        </div>
                    </div>
                    <div className='spacing-8' />
                    <p className='h13 fc-gray-400'>powered by GENTOO</p>
                </div>
            </div>
        </div>
    )
}
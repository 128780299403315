import React, {useState, forwardRef} from "react";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import '../../style/react-datepicker.css';

// Calendar.jsx 파일에 합치거나, 레거시를 정리할 필요가 있을 듯함. 
const DateRangePicker = (props) => {
    const { startDate, endDate, setStartDate, setEndDate } = props;
    const [isCalendarOpen, setIsCalendarOpen] = useState(false);

    const handleCalendarClose = () => {
        setIsCalendarOpen(false);
    }

    const handleCalendarOpen = () => {
        setIsCalendarOpen(true);
    }

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
      };

    const CustomPicker = forwardRef(
        ({ value, onClick, className }, ref) => (
            <div 
                className={className} 
                onClick={onClick} 
                ref={ref} 
                style={{ display: 'flex', width: '265px', height: '42px', padding: '0 16px', background: 'white', border: '1px solid #E1E1E1', borderRadius: '8px', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer'}}
            >
                <p className='h10 fc-gray-700'>
                {value}
                </p>
                <img src={isCalendarOpen ? '/img/units/calendar-on.png' : '/img/units/calendar-off.png'} width={26} height={26} alt=''/>
            </div>
        )
    );

    return (
        <div style={{ display: 'flex', width: '265px', alignItems: 'center'}}>
            <div className='react-datepicker-wrapper' style={{width: '265px', height: '42px'}}>
                <DatePicker
                    selected={startDate}
                    dateFormat="yyyy/MM/dd"
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    maxDate={new Date()}
                    customInput={<CustomPicker className='custom-picker'/>}
                    onCalendarClose={handleCalendarClose}
                    onCalendarOpen={handleCalendarOpen}
                />
            </div>
        </div>
    );
}

export default DateRangePicker;
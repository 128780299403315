import anlStyle from '../../style/analytics.module.css';
import { KeywordList, KeywordModal } from './KeywordList';
import { TopCharts } from './TopCharts';
import '../../../node_modules/pikaday/css/pikaday.css';
import { Calendar } from '../Units/Calendar';
import { ProdBookmark } from './ProdBookmark';
import { useEffect, useState } from 'react';
import { getRecentMonday } from '../../apis/api/utils';
import { testApi } from '../../apis/api/analytics/kpis';
import Prep from '../Prep';


const Analytics = () => {
    let today = new Date();
    let recentMonday = getRecentMonday();
    let recentSunday = new Date(recentMonday);
    recentSunday.setDate(recentMonday.getDate() - 7);
    
    const formatDate = (m, d, y) => {
        if (d < 10) {return `${m+1}월 ${d}일, ${y}`}
        else {return `${m+1}월 ${d}일, ${y}`}
    }
    
    let formattedToday = formatDate(today.getMonth(), today.getDate(), today.getFullYear());
    
    const [displayedDate, setDisplayedDate] = useState(formatDate(recentMonday.getMonth(), recentMonday.getDate(), recentMonday.getFullYear()));
    const [dateRange, setDateRange] = useState([recentSunday, recentMonday]);
    const [startDate, endDate] = dateRange;
    const [from, setFrom] = useState(startDate ? `${startDate.getFullYear()}-${startDate.getMonth() < 9 ? '0' : ''}${startDate.getMonth()+1}-${startDate.getDate() < 10 ? '0' : ''}${startDate.getDate()}`: null);
    const [to, setTo] = useState(endDate ? `${endDate.getFullYear()}-${endDate.getMonth() < 9 ? '0' : ''}${endDate.getMonth()+1}-${endDate.getDate() < 10 ? '0' : ''}${endDate.getDate()}` : null);

    const [displayedRange, setDisplayedRange] = useState(formatDate(startDate?.getMonth(), startDate?.getDate(), startDate?.getFullYear()))

    const updateDateRange = (update) => {
        setDateRange(update);
    }

    useEffect(() => {
        const newDate = `${formatDate(startDate.getMonth(), startDate.getDate(), startDate.getFullYear())} ${endDate ? (' ~ ' + formatDate(endDate.getMonth(), endDate.getDate(), endDate.getFullYear())): ''}`;
        setDisplayedRange(newDate);
        setDisplayedDate(newDate);
        setFrom(`${startDate.getFullYear()}-${startDate.getMonth() < 9 ? '0' : ''}${startDate.getMonth()+1}-${startDate.getDate() < 10 ? '0' : ''}${startDate.getDate()}`);
        setTo(endDate && `${endDate.getFullYear()}-${endDate.getMonth() < 9 ? '0' : ''}${endDate.getMonth()+1}-${endDate.getDate() < 10 ? '0' : ''}${endDate.getDate()}`);
    } ,[displayedDate, dateRange, startDate, endDate])

    // useEffect(() => {
    //     testApi();
    // }, [])
    return (
        <div className={anlStyle.Analytics__Container}>
            <div className={anlStyle.Analytics__Header}>
                <div className={anlStyle.Analytics__Header__Tab__selected}>
                    <p className='h6 fc-prim-800'>젠투 에이전트 분석</p>
                </div>
                <div className={anlStyle.Analytics__Header__Blank} />
                {/* 공사 중 
                <div className={anlStyle.Analytics__Header__RightSection}>
                    <div className={anlStyle.Analytics__Download}>
                        <div className={anlStyle.Analytics__Download__Btn}>
                            <img src='/img/analytics/download.png' width={24} height={24}/>
                            <div className='spacing-4'/>
                            <p className='h6 fc-gray-600'>통계 다운로드</p>
                        </div>
                    </div>
                    <div className='spacing-16'/>
                    <Calendar 
                        type="analytics"
                        displayedDate={displayedDate}
                        setDisplayedDate={setDisplayedDate}
                        displayedRange={displayedRange}
                        setDisplayedRange={setDisplayedRange}
                        startDate={startDate}
                        endDate={endDate}
                        dateRange={dateRange}
                        updateDateRange={updateDateRange}
                    />
                </div> */}
            </div>
            {/* 공사 중
            <div className='spacing-32' /> */}
            <Prep 
                smallText={`더 나은 서비스를 위해 준비 중입니다.\n현재 준비 중인 기능은 하단의 버튼을 통해\n먼저 체험해 보실 수 있습니다.`}
                betaBtn={true} 
                betaBtnLink={'http://3.38.253.160:8502/'} 
            />
            {/* 공사 중
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div className={anlStyle.Analytics__Contents} style={{maxWidth: '1280px'}}>
                    <TopCharts
                        from={from}
                        to={to}
                    />
                </div>
                <div className='spacing-32' />
                <div className={anlStyle.Analytics__Contents} style={{maxWidth: '1280px'}}>
                    <KeywordList 
                        from={from}
                        to={to}
                    />
                </div>
            </div> */}
        </div>
    )
}

export default Analytics
import Chart from 'react-apexcharts'
import ApexCharts from 'apexcharts';
import React, { useRef, useState, useEffect } from 'react'
import {CustomTooltip} from '../Units/CustomTooltip';
import { DBBarGraphLOption, debug_DBLineGraphSOption, DBLineGraphLOption, DBLineGraphSOption } from '../../data/chartOptions';
import dbChartsStyle from '../../style/dashboardCharts.module.css';
import '../../style/apexcharts.css'
import { getFormattedNumberValue, getFormattedNumberWithSuffix } from '../../apis/api/utils';

const createDBLineSmallOptions = ({ colors, tooltip }) => ({
    ...DBLineGraphSOption,
    colors,
    tooltip,
})

const debug_createDBLineSmallOptions = ({ colors, tooltip }) => ({
    ...debug_DBLineGraphSOption,
    colors,
    tooltip,
})

export const SalesChart = (props) => {
    const { isLoading, noData, data, dbLineGraphSIdx, startDate, endDate } = props;
    const [graphValue, setGraphValue] = useState(null);
    const formattedStartDate = formatStartEndDate(startDate);
    const formattedEndDate = formatStartEndDate(endDate);
    const [series, setSeries] = useState(null);

    //const series = [{...data?.series, name: '결제액'}];

    const salesChartOptions = createDBLineSmallOptions({
        colors: ['#0025E4'],
        tooltip: {
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const formattedDate = w.config.series[seriesIndex].data[dataPointIndex].x;
                const formattedValue = formatValue(w, seriesIndex, dataPointIndex);

                return DBLineGraphS_CustomTooltip(w, seriesIndex, formattedDate, formattedValue, '#0025E4', '₩', 'prefix');
              },
        },
    });

    useEffect(() => {
        if (data && Array.isArray(data[dbLineGraphSIdx].series.data)) {
            setGraphValue((_) => {
                const newVal = data[dbLineGraphSIdx].textData.value;
                if (newVal === null) return null;
                const formattedNewVal = getFormattedNumberValue(newVal);
                return formattedNewVal;
            });
            //window.dispatchEvent(new Event('resize'))
            setSeries([{...data[dbLineGraphSIdx].series, name: '결제액'}])
        }
    }, [data])

    return (
        <div className={dbChartsStyle.DBLineGraphS__Container} style={{ width: '100%'}}>
            <div className={dbChartsStyle.DBLineGraphS__TextWrap}>
                <div className={dbChartsStyle.DBLineGraphS__Category}>
                    <p style={{width: 'fit-content'}} className='h6 fc-gray-600'>결제액</p>
                    <div className='spacing-4' />
                    <CustomTooltip tipText={"젠투 추천 72시간 내, 결제취소 미포함"} />
                </div>
                <div className='spacing-8' />
                {
                    isLoading ? (
                        <img src='/img/units/loading-ani-S.gif' width={105} height={30}/>
                    ) : (
                            <p className='h3 fc-gray-800'>
                                {
                                    (noData || !series || !series[0]?.data?.length) ? `No data` :
                                        (data[dbLineGraphSIdx]?.textData.unitPosition === 'prefix' 
                                        ? `${data[dbLineGraphSIdx]?.textData.unit}${graphValue}` 
                                        : `${graphValue}${data[dbLineGraphSIdx]?.textData.unit}`)
                                }
                            </p>
                    )
                }
            </div>
            <div className={dbChartsStyle.DBLineGraphS__GraphWrap} style={{ width: '100%',}}>
                {
                    isLoading ? (
                        <div style={{ width: '100%', height: '182px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src='/img/units/loading-ani-L.gif' width={261} height={121} style={{ objectFit: 'cover' }}/>
                        </div>
                    ) : (
                            (noData || !series || !series[0]?.data?.length) ? ( 
                                <div style={{ width: '100%', height: '182px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <p className='h6 fc-gray-600'>no data</p>
                                </div>
                            ) :
                            <>
                                {/* <div style={{ width: '100%', display: 'block'}}>
                                    <div id="sales-chart-render" style={{ width: '100%', height: '140px' }} />
                                </div> */}
                                <div style={{ width: '100%', minWidth: '0', overflow: 'hidden', display: 'block'}}>
                                    <Chart key={JSON.stringify(salesChartOptions)} options={salesChartOptions} series={series} type="area" height='140px' width='100%' />
                                </div>
                                <div className='spacing-16'/>
                                <hr className={dbChartsStyle.DBLineGraphS__XAxis}/>
                                <div className='spacing-8'/>
                                <div className={dbChartsStyle.DBLineGraphS__Dates}>
                                    <p className='h10 fc-gray-400' style={{ cursor: 'default'}}>{formattedStartDate}</p>
                                    <p className='h10 fc-gray-400' style={{ cursor: 'default'}}>{formattedEndDate}</p>
                                </div>
                            </>
                    )
                }
            </div>
        </div>
    );
}

export const UsersChart = (props) => {
    const { isLoading, noData, data, dbLineGraphSIdx, startDate, endDate } = props;
    const [graphValue, setGraphValue] = useState(null);
    const formattedStartDate = formatStartEndDate(startDate);
    const formattedEndDate = formatStartEndDate(endDate);
    const [series, setSeries] = useState(null);

    //const series = [{...data?.series, name: '구매자 수'}];

    const usersChartOptions = createDBLineSmallOptions({
        colors: ['#6225C6'],
        tooltip: {
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const formattedDate = w.config.series[seriesIndex].data[dataPointIndex].x;
                const formattedValue = formatValue(w, seriesIndex, dataPointIndex);

                return DBLineGraphS_CustomTooltip(w, seriesIndex, formattedDate, formattedValue, '#6225C6', '', '');
              }
        }
    });

    useEffect(() => {
        if (data && Array.isArray(data[dbLineGraphSIdx].series.data)) {
            setGraphValue((_) => {
                const newVal = data[dbLineGraphSIdx].textData.value;
                if (newVal === null) return null;
                const formattedNewVal = getFormattedNumberValue(newVal);
                return formattedNewVal;
            });
            setSeries([{...data[dbLineGraphSIdx].series, name: '구매자 수'}])
        }
    }, [data])

    return (
        <div className={dbChartsStyle.DBLineGraphS__Container}>
            <div className={dbChartsStyle.DBLineGraphS__TextWrap}>
                <div className={dbChartsStyle.DBLineGraphS__Category}>
                    <p style={{width: 'fit-content'}} className='h6 fc-gray-600'>구매자 수</p>
                    <div className='spacing-4'/>
                    <CustomTooltip tipText={"젠투 추천 72시간 내, 유저 토큰 기준"} />
                </div>
                <div className='spacing-8' />
                {
                    isLoading ? (
                        <img src='/img/units/loading-ani-S.gif' width={105} height={30}/>
                    ) : (
                            <p className='h3 fc-gray-800'>
                                {
                                    (noData || !series || !series[0]?.data?.length) ? `No data` :
                                        (data[dbLineGraphSIdx]?.textData.unitPosition === 'prefix' 
                                        ? `${data[dbLineGraphSIdx]?.textData.unit}${graphValue}` 
                                        : `${graphValue}${data[dbLineGraphSIdx]?.textData.unit}`)
                                }
                            </p>
                    )
                }
            </div>
            <div className={dbChartsStyle.DBLineGraphS__GraphWrap}>
                {
                    isLoading ? (
                        <div style={{ width: '100%', height: '182px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src='/img/units/loading-ani-L.gif' width={261} height={121} style={{ objectFit: 'cover' }}/>
                        </div>
                    ) : (
                            (noData || !series || !series[0]?.data?.length) ? ( 
                                <div style={{ width: '100%', height: '182px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <p className='h6 fc-gray-600'>no data</p>
                                </div>
                            ) :
                            <>
                                <Chart options={usersChartOptions} series={series} type="area" height='140px' />
                                <div className='spacing-16'/>
                                <hr className={dbChartsStyle.DBLineGraphS__XAxis}/>
                                <div className='spacing-8'/>
                                <div className={dbChartsStyle.DBLineGraphS__Dates}>
                                    <p className='h10 fc-gray-400' style={{ cursor: 'default'}}>{formattedStartDate}</p>
                                    <p className='h10 fc-gray-400' style={{ cursor: 'default'}}>{formattedEndDate}</p>
                                </div>
                            </>
                    )
                }
            </div>
        </div>
    );
}

export const ConversionRateChart = (props) => {
    const { isLoading, noData, data, dbLineGraphSIdx, startDate, endDate } = props;
    const [graphValue, setGraphValue] = useState(null);
    const formattedStartDate = formatStartEndDate(startDate);
    const formattedEndDate = formatStartEndDate(endDate);
    const [series, setSeries] = useState(null);

    //const series = [{...data?.series, name: '노출 대비 구매 전환율'}];

    const conversionRateChartOptions = createDBLineSmallOptions({
        colors: ['#49ADE5'],
        tooltip: {
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const formattedDate = w.config.series[seriesIndex].data[dataPointIndex].x;
                const formattedValue = formatValue(w, seriesIndex, dataPointIndex);

                return DBLineGraphS_CustomTooltip(w, seriesIndex, formattedDate, formattedValue, '#49ADE5', '%', 'suffix');
              }
        }
    });

    useEffect(() => {
        if (data && Array.isArray(data[dbLineGraphSIdx].series.data)) {
            setGraphValue((_) => {
                const newVal = data[dbLineGraphSIdx].textData.value;
                if (newVal === null) return null;
                const formattedNewVal = getFormattedNumberValue(newVal);
                return formattedNewVal;
            });
            setSeries([{...data[dbLineGraphSIdx].series, name: '노출 대비 구매 전환율'}])
        }
    }, [data])

    return (
        <div className={dbChartsStyle.DBLineGraphS__Container}>
            <div className={dbChartsStyle.DBLineGraphS__TextWrap}>
                <div className={dbChartsStyle.DBLineGraphS__Category}>
                    <p style={{width: 'fit-content'}} className='h6 fc-gray-600'>노출 대비 구매 전환율</p>
                    <div className='spacing-4'/>
                    <CustomTooltip tipText={"젠투 버튼 노출 횟수 대비 구매 건수"} />
                </div>
                <div className='spacing-8' />
                {
                    isLoading ? (
                        <img src='/img/units/loading-ani-S.gif' width={105} height={30}/>
                    ) : (
                            <p className='h3 fc-gray-800'>
                                {
                                    (noData || !series || !series[0]?.data?.length) ? `No data` :
                                        (data[dbLineGraphSIdx]?.textData.unitPosition === 'prefix' 
                                        ? `${data[dbLineGraphSIdx]?.textData.unit}${graphValue}` 
                                        : `${graphValue}${data[dbLineGraphSIdx]?.textData.unit}`)
                                }
                            </p>
                    )
                }
            </div>
            <div className={dbChartsStyle.DBLineGraphS__GraphWrap}>
                {
                    isLoading ? (
                        <div style={{ width: '100%', height: '182px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src='/img/units/loading-ani-L.gif' width={261} height={121} style={{ objectFit: 'cover' }}/>
                        </div>
                    ) : (
                            (noData || !series || !series[0]?.data?.length) ? ( 
                                <div style={{ width: '100%', height: '182px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <p className='h6 fc-gray-600'>no data</p>
                                </div>
                            ) :
                            <>
                                <Chart options={conversionRateChartOptions} series={series} type="area" height='140px' />
                                <div className='spacing-16'/>
                                <hr className={dbChartsStyle.DBLineGraphS__XAxis}/>
                                <div className='spacing-8'/>
                                <div className={dbChartsStyle.DBLineGraphS__Dates}>
                                    <p className='h10 fc-gray-400' style={{ cursor: 'default'}}>{formattedStartDate}</p>
                                    <p className='h10 fc-gray-400' style={{ cursor: 'default'}}>{formattedEndDate}</p>
                                </div>
                            </>
                    )
                }
            </div>
        </div>
    );
}


export const ConversationChart = (props) => {
    const { isLoading, noData, data, dbLineGraphSIdx, startDate, endDate } = props;
    const [graphValue, setGraphValue] = useState(null);
    const formattedStartDate = formatStartEndDate(startDate);
    const formattedEndDate = formatStartEndDate(endDate);
    const [series, setSeries] = useState(null);

    //const series = [{...data?.series, name: '대화쌍 수'}];

    const conversationChartOptions = createDBLineSmallOptions({
        colors: ['#103B9C'],
        tooltip: {
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const formattedDate = w.config.series[seriesIndex].data[dataPointIndex].x;
                const formattedValue = formatValue(w, seriesIndex, dataPointIndex);

                return DBLineGraphS_CustomTooltip(w, seriesIndex, formattedDate, formattedValue, '#103B9C', '', '');
              },
        }
    });

    useEffect(() => {
        if (data && Array.isArray(data[dbLineGraphSIdx].series.data)) {
            setGraphValue((_) => {
                const newVal = data[dbLineGraphSIdx].textData.value;
                if (newVal === null) return null;
                const formattedNewVal = getFormattedNumberValue(newVal);
                return formattedNewVal;
            });
            setSeries([{...data[dbLineGraphSIdx].series, name: '대화쌍 수'}])
        }
    }, [data])

    return (
            <div className={dbChartsStyle.DBLineGraphS__Container}>
                <div className={dbChartsStyle.DBLineGraphS__TextWrap}>
                    <div className={dbChartsStyle.DBLineGraphS__Category}>
                        <p style={{width: 'fit-content'}} className='h6 fc-gray-600'>대화쌍 수</p>
                        <div className='spacing-4'/>
                        <CustomTooltip tipText={"젠투와 대화를 주고받은 총 횟수"} />
                    </div>
                    <div className='spacing-8' />
                    {
                        isLoading ? (
                            <img src='/img/units/loading-ani-S.gif' width={105} height={30}/>
                        ) : (
                                <p className='h3 fc-gray-800'>
                                    {
                                        (noData || !series || !series[0]?.data?.length) ? `No data` :
                                            (data[dbLineGraphSIdx]?.textData.unitPosition === 'prefix' 
                                            ? `${data[dbLineGraphSIdx]?.textData.unit}${graphValue}` 
                                            : `${graphValue}${data[dbLineGraphSIdx]?.textData.unit}`)
                                    }
                                </p>
                        )
                    }
                </div>
                <div className={dbChartsStyle.DBLineGraphS__GraphWrap}>
                    {
                        isLoading ? (
                            <div style={{ width: '100%', height: '182px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <img src='/img/units/loading-ani-L.gif' width={261} height={121} style={{ objectFit: 'cover' }}/>
                            </div>
                        ) : (
                                (noData || !series || !series[0]?.data?.length) ? ( 
                                    <div style={{ width: '100%', height: '182px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <p className='h6 fc-gray-600'>no data</p>
                                    </div>
                                ) :
                                <>
                                    <Chart options={conversationChartOptions} series={series} type="area" height='140px' />
                                    <div className='spacing-16'/>
                                    <hr className={dbChartsStyle.DBLineGraphS__XAxis}/>
                                    <div className='spacing-8'/>
                                    <div className={dbChartsStyle.DBLineGraphS__Dates}>
                                        <p className='h10 fc-gray-400' style={{ cursor: 'default'}}>{formattedStartDate}</p>
                                        <p className='h10 fc-gray-400' style={{ cursor: 'default'}}>{formattedEndDate}</p>
                                    </div>
                                </>
                        )
                    }
                </div>
            </div>
    );
}

export const ARPPUChart = (props) => {
    const { isLoading, noData, data, dbLineGraphSIdx, startDate, endDate } = props;
    const [graphValue, setGraphValue] = useState(null);
    const formattedStartDate = formatStartEndDate(startDate);
    const formattedEndDate = formatStartEndDate(endDate);
    const [series, setSeries] = useState(null);

    //const series = [{...data?.series, name: 'ARPPU'}];

    const ARPPUChartOptions = createDBLineSmallOptions({
        colors: ['#1C89A1'],
        tooltip: {
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const formattedDate = w.config.series[seriesIndex].data[dataPointIndex].x;
                const formattedValue = formatValue(w, seriesIndex, dataPointIndex);

                return DBLineGraphS_CustomTooltip(w, seriesIndex, formattedDate, formattedValue, '#1C89A1', '₩', 'prefix');
              },
        }
    });

    useEffect(() => {
        if (data && Array.isArray(data[dbLineGraphSIdx].series.data)) {
            setGraphValue((_) => {
                const newVal = data[dbLineGraphSIdx].textData.value;
                if (newVal === null) return null;
                const formattedDecimalVal = Math.floor(newVal);
                const formattedNewVal = getFormattedNumberValue(formattedDecimalVal);
                return formattedNewVal;
            });
            setSeries([{...data[dbLineGraphSIdx].series, name: 'ARPPU'}])
        }
    }, [data])

    return (
        <div className={dbChartsStyle.DBLineGraphS__Container}>
            <div className={dbChartsStyle.DBLineGraphS__TextWrap}>
                <div className={dbChartsStyle.DBLineGraphS__Category}>
                    <p style={{width: 'fit-content'}} className='h6 fc-gray-600'>ARPPU</p>
                    <div className='spacing-4'/>
                    <CustomTooltip tipText={"결제 고객 당 평균 객단가"} />
                </div>
                <div className='spacing-8' />
                {
                    isLoading ? (
                        <img src='/img/units/loading-ani-S.gif' width={105} height={30}/>
                    ) : (
                            <p className='h3 fc-gray-800'>
                                {
                                    (noData || !series || !series[0]?.data?.length) ? `No data` :
                                        (data[dbLineGraphSIdx]?.textData.unitPosition === 'prefix' 
                                        ? `${data[dbLineGraphSIdx]?.textData.unit}${graphValue}` 
                                        : `${graphValue}${data[dbLineGraphSIdx]?.textData.unit}`)
                                }
                            </p>
                    )
                }
            </div>
            <div className={dbChartsStyle.DBLineGraphS__GraphWrap}>
                {
                    isLoading ? (
                        <div style={{ width: '100%', height: '182px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src='/img/units/loading-ani-L.gif' width={261} height={121} style={{ objectFit: 'cover' }}/>
                        </div>
                    ) : (
                            (noData || !series || !series[0]?.data?.length) ? ( 
                                <div style={{ width: '100%', height: '182px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <p className='h6 fc-gray-600'>no data</p>
                                </div>
                            ) :
                            <>
                                <Chart options={ARPPUChartOptions} series={series} type="area" height='140px' />
                                <div className='spacing-16'/>
                                <hr className={dbChartsStyle.DBLineGraphS__XAxis}/>
                                <div className='spacing-8'/>
                                <div className={dbChartsStyle.DBLineGraphS__Dates}>
                                    <p className='h10 fc-gray-400' style={{ cursor: 'default'}}>{formattedStartDate}</p>
                                    <p className='h10 fc-gray-400' style={{ cursor: 'default'}}>{formattedEndDate}</p>
                                </div>
                            </>
                    )
                }
            </div>
        </div>
    );
}

export const CartNPurchaseChart = (props) => {
    const { isLoading, noData, data, startDate, endDate } = props;
    const formattedStartDate = formatStartEndDate(startDate);
    const formattedEndDate = formatStartEndDate(endDate);
    const [series, setSeries] = useState(null);

    //const series = data?.multiSeries;

    const options = {
        ...DBLineGraphLOption,
        tooltip: {
            enabled: true,
            shared: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const formattedDate = w.config.series[seriesIndex].data[dataPointIndex].x;

                const rawValueCart = w.config.series[0].data[dataPointIndex].y; 
                const formattedValueCart = !rawValueCart ? ( rawValueCart === null ? null : 0) : getFormattedNumberValue(rawValueCart);

                const rawValuePurchase = w.config.series[1].data[dataPointIndex].y; 
                const formattedValuePurchase = !rawValuePurchase ? (rawValuePurchase === null ? null : 0) : getFormattedNumberValue(rawValuePurchase);

                return DBLineGraphL_CustomTooltip(w, seriesIndex, formattedDate, [formattedValueCart, formattedValuePurchase], ['#BBC8E5', '#154CCA']);
              },
        },
    };

    useEffect(() => {
        if (data && Array.isArray(data[0].multiSeries)) {
            setSeries(data[0].multiSeries)
        }
    }, [data])


    return (
            <div className={dbChartsStyle.DBLineGraphL__Container}>
                {
                    isLoading ? (
                        <div style={{ width: '100%', height: '335px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src='/img/units/loading-ani-L.gif' width={572} height={322} style={{ objectFit: 'cover' }}/>
                        </div>
                    ) : (
                            (noData || !series) ? ( 
                                <div style={{ width: '100%', height: '335px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <p className='h3 fc-gray-600'>No data</p>
                                </div>
                            ) :
                            <>
                                <Chart options={options} series={series} type="line" height={320}/>
                                <p className={`${dbChartsStyle.DBLineGraphL__Date__left} h9 fc-gray-600`}>{formattedStartDate}</p>
                                <p className={`${dbChartsStyle.DBLineGraphL__Date__right} h9 fc-gray-600`}>{formattedEndDate}</p>
                            </>
                    )
                }
            </div>
    );
}

export const FunnelChart = (props) => {
    const { isLoading, noData, data } = props;
    const [series, setSeries] = useState(null);

    //const series = [{...data?.series, name: '상품 탐색 퍼널'}];

    const options = {
        ...DBBarGraphLOption,
        tooltip: {
            enabled: true,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const rawValue = w.config.series[seriesIndex].data[dataPointIndex].y; 
                const formattedValue = getFormattedNumberWithSuffix(rawValue)
                
                return DBBarGraphL_CustomTooltip(w, seriesIndex, dataPointIndex, formattedValue, '#154CCA');
              },
        },
    };

    useEffect(() => {
        if (data && Array.isArray(data[0].series.data)) {
            setSeries([{...data[0].series, name: '상품 탐색 퍼널'}]);
        }
    }, [data])

    return (
            <div className={dbChartsStyle.DBBarGraphL__Container}>
                {
                    isLoading ? (
                        <div style={{ width: '100%', height: '495px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <img src='/img/units/loading-ani-L.gif' width={839} height={472} style={{ objectFit: 'cover' }}/>
                        </div>
                    ) : (
                            (noData || !series) ? ( 
                                <div style={{ width: '100%', height: '495px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <p className='h3 fc-gray-600'>No data</p>
                                </div>
                            ) :
                            <Chart options={options} series={series} type="bar" height={480} />
                    )
                }
            </div>
    );
}

const formatStartEndDate = (dateStr) => {
    const date = new Date(dateStr);
    const formattedDate = date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });

    return formattedDate;
}

const formatValue = (w, seriesIndex, dataPointIndex) => {
    const rawValue = w.config.series[seriesIndex].data[dataPointIndex].y; 
    if (rawValue === null) return null;
    const formattedValue = new Intl.NumberFormat('en-US').format(rawValue);
    return formattedValue;
}

const DBLineGraphS_CustomTooltip = (w, seriesIndex, formattedX, formattedY, color, unit, unitPosition) => {
    const formattedYwithUnit = unitPosition === 'prefix' 
        ? `${unit}${formattedY}` 
        : `${formattedY}${unit}`;
    
    return (
        '<div class="custom-tooltip" style="display: flex; flex-direction: column; width: fit-content; padding: 8px 12px; background: #FFF; gap: 8px; ">' +
            '<p style="font-size: 12px; color: #666666; font-weight: 400;">' + formattedX + '</p>' +
            '<div class="custom-tooltip-body" style="display: flex; align-items: center; gap: 12px; align-self: stretch;">' +
                '<div class="custom-tooltip-label" style="display: flex; align-items: center; gap: 4px; width: fit-content; justify-content: flex-start;">' + 
                    '<div style="width: 14px; height: 14px; border-radius: 4px; background:' + color + ';"></div>' +
                    '<p style="font-size: 12px; color: #666666; font-weight: 600">' + w.config.series[seriesIndex].name + '</p>' +
                '</div>' +
                '<p style="font-size: 12px; color: #222222; font-weight: 600">' + formattedYwithUnit + '</p>' +
            '</div>' +
        '</div>'
    );
}

const DBLineGraphL_CustomTooltip = (w, seriesIndex, formattedX, formattedYs, colors) => {
    return (
        '<div class="custom-tooltip" style="display: flex; flex-direction: column; width: fit-content; padding: 8px 12px; background: #FFF; gap: 8px; ">' +
            '<p style="font-size: 12px; color: #666666; font-weight: 400;">' + formattedX + '</p>' +
            '<div class="custom-tooltip-body" style="display: flex; align-items: center; gap: 12px; align-self: stretch;">' +
                '<div class="custom-tooltip-label" style="display: flex; align-items: center; gap: 4px; width: fit-content; justify-content: flex-start;">' + 
                    '<div style="width: 14px; height: 14px; border-radius: 4px; background:' + colors[0] + ';"></div>' +
                    '<p style="font-size: 12px; color: #666666; font-weight: 600">총 장바구니 담기 건수</p>' +
                '</div>' +
                '<p style="font-size: 12px; color: #222222; font-weight: 600">' + formattedYs[0] + '</p>' +
            '</div>' +
            '<div class="custom-tooltip-body" style="display: flex; align-items: center; gap: 12px; align-self: stretch;">' +
                '<div class="custom-tooltip-label" style="display: flex; align-items: center; gap: 4px; width: fit-content; justify-content: flex-start;">' + 
                    '<div style="width: 14px; height: 14px; border-radius: 4px; background: ' + colors[1] + ';"></div>' +
                    '<p style="font-size: 12px; color: #666666; font-weight: 600">총 구매 건수</p>' +
                '</div>' +
                '<p style="font-size: 12px; color: #222222; font-weight: 600">' + formattedYs[1] + '</p>' +
            '</div>' +
        '</div>'
    );
}

const DBBarGraphL_CustomTooltip = (w, seriesIndex, dataPointIndex, formattedY, color) => {
    return (
        '<div class="custom-tooltip" style="display: flex; flex-direction: column; width: fit-content; padding: 8px 12px; background: #FFF; gap: 8px; ">' +
            '<div class="custom-tooltip-body" style="display: flex; align-items: center; gap: 12px; align-self: stretch;">' +
                '<div class="custom-tooltip-label" style="display: flex; align-items: center; gap: 4px; width: fit-content; justify-content: flex-start;">' + 
                    '<div style="width: 14px; height: 14px; border-radius: 4px; background: ' + color + ';"></div>' +
                    '<p style="font-size: 12px; color: #666666; font-weight: 600">' + w.globals.labels[dataPointIndex] + '</p>' +
                '</div>' +
                '<p style="font-size: 12px; color: #222222; font-weight: 600">' + formattedY + '</p>' +
            '</div>' +
        '</div>'
    );
}
import { useEffect, useState } from "react"
import widgetStyle from '../../style/units.module.css';
import agentStyle from '../../style/agent.module.css';
import { ErrorMessage } from "../Units/Alerts";
import { updateChatbotInfo } from "../../apis/services/create/api";

export const WidgetSet = (props) => {
    const {compProps} = props;
    const clickedButton = compProps.clickedButton;
    const setClickedButton = compProps.setClickedButton;

    /* const handleButtonClick = (e) => {
        const buttonId = e.target.dataset.buttonid;
        let clickedButtonCopy = [...clickedButton];
        clickedButtonCopy[buttonId] = !clickedButtonCopy[buttonId];
        setClickedButton(clickedButtonCopy);
    } */

    const handleButtonClick = (id) => {
        const buttonId = id;
        let clickedButtonCopy = [...clickedButton];
        clickedButtonCopy[buttonId] = !clickedButtonCopy[buttonId];
        setClickedButton(clickedButtonCopy);
    }

    return (
        <div>
            <div
                className={widgetStyle.WidgetSet__Container}
            >
                <button
                    data-buttonid={0}
                    className={clickedButton[0] ? widgetStyle.WidgetSet__Button__active : widgetStyle.WidgetSet__Button}
                    onClick={() => {handleButtonClick(0)}}  // 텍스트 clickable 하도록 하기 위해 id 직접 넘기는 방식으로 수정했습니다
                >
                    <p className={`h6 ${clickedButton[0] ? 'fc-prim-800': 'fc-gray-700'}`} >플로팅 버튼</p>
                </button>
                <div className="spacing-8" />
                <button
                    className={widgetStyle.WidgetSet__Button__disabled}
                    disabled={true}
                >
                    <p className="h6 fc-gray-400">추천 섹션(준비 중인 기능이에요)</p>
                </button>
            </div>
            {
                clickedButton[0] &&
                <>
                    <div style={{borderBottom: '2px dashed #e1e1e1'}}>
                        <div className="spacing-32" />
                    </div>
                    <div>
                        <PosCustom compProps={compProps} />
                    </div>
                </>
            }
        </div>
    )
}

export const PosCustom = (props) => {
    const {compProps} = props;
    const setIsToastVisible = compProps.setIsToastVisible;
    const initSettings = compProps.initSettings;
    const updateSetting = compProps.updateSetting;
    const setIsModalVisible = compProps.setIsModalVisible;
    const partnerId = sessionStorage.getItem('ptid');
    const partnerType = sessionStorage.getItem('ptt');
    const chatbotId = sessionStorage.getItem('cbid');
    const userAccessLevel = sessionStorage.getItem('l');

    const [fbPos, setFbPos] = useState({
        top: null,
        bottom: 66,
        left: null,
        right: 0,
    });

    useEffect(() => {
        updateSetting('position', fbPos);
    }, [fbPos])
    

    return (
        <div>
            <div className={widgetStyle.Accodian__TopWrapper} style={{paddingLeft: 0, paddingRight: 0}}>
                <div className={widgetStyle.Accodian__BadgeWrapper}>
                    <div
                        className={widgetStyle.Accodian__TopBadge}
                    >
                        <p className="h8 fc-prim-800">위젯설정</p>
                    </div>
                    <div className="spacing-12" />
                    <p className="h4 fc-gray-700">플로팅 버튼</p>
                </div>
                <div style={{ visibility: 'hidden' }}>
                    <ErrorMessage text={`추천 섹션은 아래의 배포 버튼을 통해 자동으로 배포되지 않습니다.\n다음 HTML 코드를 복사하여 원하는 화면의 해당 위치에 직접 삽입 해야 합니다`} />
                </div>
            </div>
            <div style={{width: '100%', height: 'fit-content', display: 'flex'}}>
                <div style={{width: '640px', height: '360px', background: '#efefef', borderRadius: '20px', position: 'relative'}}>
                    <img src="/img/units/designmode-fb.png" width={105} height={32} style={{position: 'absolute', bottom: fbPos.bottom/2.5, right: fbPos.right/2.5}}/>
                </div>
                <div className="spacing-32" />
                <div style={{flex: 1, height: '360px', display: 'flex'}}>
                    <p className="h6 fc-gray-600" style={{minWidth: '70px'}}>위치 조정</p>
                    <div className="spacing-24" />
                    <div style={{width: '100%'}}>
                        <div style={{width: '100%', display: 'flex'}}>
                            <label htmlFor="posx" className="h6 fc-gray-600" style={{minWidth: '26px'}}>가로</label>
                            <div className="spacing-16" />
                            <div style={{width: '100%', position: 'relative'}}>
                                <input 
                                    type="range" 
                                    name="posx"
                                    style={{width: '100%'}} 
                                    min={0}
                                    max={200}
                                    step={1}
                                    value={fbPos.right}
                                    onChange={(e) => {
                                        setFbPos(res => 
                                            ({
                                                ...res,
                                                right: Number(e.target.value),
                                            }))
                                    }} 
                                    // disabled={true}
                                />
                                <div style={{
                                    width: 'fit-content', 
                                    height: 'fit-content', 
                                    position: 'absolute', 
                                    top: -12, 
                                    left: (fbPos.right/200)*170,
                                    background: '#444',
                                    borderRadius: '4px',
                                    padding: '4px'
                                }}>
                                    <p className="h12 fc-white">{fbPos.right}</p>
                                </div>
                            </div>
                        </div>
                        <div className="spacing-32" />
                        <div style={{width: '100%', display: 'flex'}}>
                            <label htmlFor="posy" className="h6 fc-gray-600" style={{minWidth: '26px'}}>세로</label>
                            <div className="spacing-16" />
                            <div style={{width: '100%', position: 'relative'}}>
                                <input 
                                    type="range" 
                                    name="posy"
                                    style={{width: '100%'}} 
                                    min={0}
                                    max={200}
                                    step={1}
                                    onChange={(e) => {
                                        setFbPos(res => 
                                            ({
                                                ...res,
                                                bottom: Number(e.target.value),
                                            }))
                                    }} 
                                />
                                <div style={{
                                    width: 'fit-content', 
                                    height: 'fit-content', 
                                    position: 'absolute', 
                                    top: -12, 
                                    left: (fbPos.bottom/200)*170,
                                    background: '#444',
                                    borderRadius: '4px',
                                    padding: '4px'
                                }}>
                                    <p className="h12 fc-white">{fbPos.bottom}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacing-48" />
            <div className={agentStyle.AgentCustom__ButtonWrap}>
                <button 
                    className={widgetStyle.WidgetSet__PreviewButton}
                    onClick={() => {
                        window.open(`/deploy-preview?posX=${fbPos.right}&posY=${fbPos.bottom}`, '_blank');
                    }}
                >
                    <p className="h4 fc-prim-800">미리보기</p>
                    <img src='/img/deployPreview/preview-open.png' width={24} height={24} />
                </button>
                <div className="spacing-8" />
                {
                    Number(userAccessLevel) === 0 ?
                    <button 
                        className={agentStyle.AgentCustom__Button} 
                        onClick={() => window.open('https://whattime.co.kr/waddle/pricing-consult', "_blank")}  
                    >
                        <p className='h4 fc-white'>유료 전환하고 배포하기</p>
                    </button> :
                    <button 
                        className={agentStyle.AgentCustom__Button} 
                        onClick={() => setIsModalVisible(true)}
                    >
                        <p className='h4 fc-white'>배포</p>
                    </button>
                }
            </div>
            <div className="spacing-24" />
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import agentStyle from '../../style/agent.module.css';
import { updateExperimentInfo } from '../../apis/services/create/api';
export const BetaFeatures = (props) => {
    const {compProps} = props;
    const setIsContentOpen = compProps.setIsContentOpen;
    const userAccessLevel = compProps.userAccessLevel;
    const initExpSettings = compProps.initExpSettings;
    const updateExpSetting = compProps.updateExpSetting;
    const setIsToastVisible = compProps.setIsToastVisible;
    const setUnsavedChanges = compProps.setUnsavedChanges;
    const partnerId = sessionStorage.getItem('ptid');
    const chatbotId = sessionStorage.getItem('cbid');
    const [tempExpSettings, setTempExpSettings] = useState(initExpSettings);    // 저장 버튼을 누르면 update

    const handleSwitchClick = () => {
        const newDetailedResponse = { ...tempExpSettings?.detailedResponse, activated: !tempExpSettings?.detailedResponse?.activated};
        const newExpSettings = { ...tempExpSettings, detailedResponse: newDetailedResponse };
        setTempExpSettings(newExpSettings);
        setUnsavedChanges(true);
    }

    const handleSaveButtonClick = () => {
        updateExperimentInfo(partnerId, chatbotId, tempExpSettings)
            .then(res => {
                updateExpSetting('detailedResponse', tempExpSettings.detailedResponse);
                setIsToastVisible(true);
                setUnsavedChanges(false);
            })
    }

    useEffect(() => {
        setTempExpSettings(initExpSettings);
    }, [initExpSettings]);

    return (
        <div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', alignSelf: 'stretch'}}>
                <div style={{display: 'flex', padding: '24px 0', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch'}}>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <p className='h6 fc-gray-600'>더 자세히 답변하기</p>
                        <div className='spacing-12'/>
                        <p className='h10 fc-gray-500'>젠투의 기본 답변 길이를 3문장에서 최대 5문장으로 늘려, 더 풍부하고 자세한 답변을 제공하도록 하는 기능입니다</p>
                    </div>
                    {
                        userAccessLevel > 0 ? (
                            <img 
                                src={tempExpSettings?.detailedResponse?.activated ? '/img/units/switch-active-on.png' : '/img/units/switch-active-off.png'} 
                                width={50} 
                                height={30} 
                                style={{cursor: 'pointer'}}
                                onClick={handleSwitchClick}
                                alt=''
                            />
                        ) : (
                            <img src='/img/units/switch-disabled-off.png' width={50} height={30} style={{cursor: 'default'}}/>
                        )
                    }
                </div>
            </div>
            <div className={agentStyle.AgentCustom__ButtonWrap} style={{padding: '48px 0 12px 0'}}>
                {
                    userAccessLevel > 0 ? (
                        <button 
                            className={agentStyle.AgentCustom__Button} 
                            onClick={() => {
                                handleSaveButtonClick();
                                setIsContentOpen(false);
                            }}
                        >
                            <p className='h4 fc-white'>저장</p>
                        </button>
                    ) : (
                        <button 
                            className={agentStyle.AgentCustom__Button} 
                            onClick={() => {
                                window.open('https://whattime.co.kr/waddle/pricing-consult', "_blank");
                            }}
                        >
                            <p className='h4 fc-white'>유료 전환하고 활성화하기</p>
                        </button>
                    )
                }
            </div>
        </div>
    );
};
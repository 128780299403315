import { useEffect, useState } from 'react';
import agentStyle from '../../style/agent.module.css';
import { ColorInput, ExceptKeyword, GreetingInput, LogoInput, NameInput, CarouselTypeInput, ExamplesInput } from '../Units/CustomSet';
import { FileDownload, FileUpload } from "../Units/FileTransfer"
import { Preview } from '../Units/Preview';
import { useNavigate, useParams } from 'react-router-dom';

export const AgentCreate = () => {
    const partnerId = useParams().partnerId;
    localStorage.setItem('user', partnerId);

    return (
        <div className={agentStyle.AgentCreate__Container}>
            <div className={agentStyle.AgentCreate__Wrap}>
                <p className='h2 fc-gray-800'>젠투 에이전트 생성</p>
                <div className='spacing-24' />
                <p className='h7 fc-gray-800'>
                    젠투 에이전트 생성을 위해 템플릿을 다운받고,
                    <br/>데이터를 입력한 뒤 파일을 업로드 해주세요.
                </p>
                <div className='spacing-32' />
                <div className={agentStyle.AgentCreate__Top}>
                    <FileDownload />
                </div>
                <div className='spacing-16' />
                <div className={agentStyle.AgentCreate__Bottom}>
                    <FileUpload acceptable='.xlsx' partnerId={partnerId} />
                </div>
            </div>
        </div>
    )
}

export const AgentCustom = (props) => {
    const {compProps} = props;
    const initSettings = compProps.initSettings;
    const updateSetting = compProps.updateSetting;
    const setIsContentOpen = compProps.setIsContentOpen;
    const setClickedButton = compProps.setClickedButton;
    const userAccessLevel = sessionStorage.getItem('l');
    let example = localStorage.getItem('example');
    const partnerId = sessionStorage.getItem('ptid');
    example = JSON.parse(example);

    const [carouselVisible, setCarouselVisible] = useState(false);

    return (
        <div className={agentStyle.AgentCustom__Container}>
            {/* <p className='h4 fc-gray-700'>인터페이스</p>
            <div className='spacing-32' /> */}
            <div className={agentStyle.AgentCustom__Wrap}>
                <div className={agentStyle.AgentCustom__Left}>
                    <Preview initSettings={initSettings} carouselVisible={carouselVisible} />
                </div>
                <div className={agentStyle.AgentCustom__Right}>
                    <LogoInput initLogo={initSettings?.profileImg} updateSetting={updateSetting} />
                    <div className='spacing-24' />
                    <NameInput initName={initSettings?.name} updateSetting={updateSetting} />
                    <div className='spacing-24' />
                    <ColorInput initColor={initSettings?.colorCode} updateSetting={updateSetting} setCarouselVisible={setCarouselVisible}/>
                    <div className='spacing-24' />
                    <GreetingInput initGreeting={initSettings?.greetingMessage} updateSetting={updateSetting}/>
                    <div className='spacing-24' />
                    <ExamplesInput initExamples={initSettings?.examples} updateSetting={updateSetting} partnerId={partnerId}/>
                    <div className='spacing-24' /> 
                    <CarouselTypeInput initCarouselType={initSettings?.carouselType} updateSetting={updateSetting} setCarouselVisible={setCarouselVisible}/>
                    <div className='spacing-48' />
                    <div className={agentStyle.AgentCustom__ButtonWrap}>
                        <button 
                            className={agentStyle.AgentCustom__Button} 
                            onClick={() => {
                                setIsContentOpen([false, true]);
                                setClickedButton([true, false]);
                            }}
                        >
                            <p className='h4 fc-white'>저장</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const getFormattedDate = (date = new Date(), type = 'full') => {
    // type: 'full', 'semi'
    const refDate = new Date(date);

    const year = refDate.getFullYear();
    const month = String(refDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더해줌
    const day = String(refDate.getDate()).padStart(2, '0');


    const formattedDate = ( type === 'full'? `${year}-${month}-${day}` :`${month}-${day}`);

    return formattedDate
}

export const getRecentMonday = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const diff = (dayOfWeek + 6) % 7;
    const lastMonday = new Date(today);
    if (dayOfWeek !== 7) {
        lastMonday.setDate(today.getDate() - diff - 7);
    } else {
        lastMonday.setDate(today.getDate() - diff);
    }
    
    return lastMonday;
}

export const getFormattedNumberValue = (valNum) => {
    // 숫자를 000,000 형식으로
    if (typeof valNum !== 'number') return 'Invalid number';
    const formattedValue = new Intl.NumberFormat('en-US').format(valNum);
    return formattedValue;
}

export const getFormattedDecimalValue = (valNum, decPlace) => {
    if (typeof valNum !== 'number') return 'Invalid number';
    const valStr = valNum.toFixed(decPlace);
    return valStr;
}

export const getFormattedNumberWithSuffix = (valNum) => {
    if (typeof valNum !== 'number') return 'Invalid number';
    if (valNum >= 1_000_000) {
        return `${(valNum / 1_000_000).toFixed(2)}M`;
    } else {
        return `${(valNum / 1_000).toFixed(2)}k`;
    }
}
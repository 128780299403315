import React, { useState, useEffect, useRef } from "react";
import dropdownStyle from '../../style/units.module.css';

const Dropdown = (props) => {
    const { options, optionValues, setOptionValues, selectedChannels, setSelectedChannels } = props;
    const [visibleText, setVisibleText] = useState(options[0].title);
    const [isExpanded, setIsExpanded] = useState(false);
    const dropdownRef = useRef(null);
    const [selected, setSelected] = useState();

    const toggleDropdown = () => {
        setIsExpanded((prev) => !prev);
    }

    const handleOptionClick = (idx) => {
        const count = optionValues.filter(val => val).length;
        setSelected(idx);
        setOptionValues((prevOptionValues) => {
            const newOptionValues = prevOptionValues.map((value, key) =>
                key === idx ? !value : value
            );
    
            // unchecked "전체" 선택 시 -> 전체
            if (((idx === 0) && !prevOptionValues[0])) {
                setVisibleText(options[0].title);
                setSelectedChannels([options[0]]);
                return [true, true, true]; 
            } //  "전체" 제외 unchecked 항목 선택 && unchecked "전체" && "전체" 제외 모두 선택 -> 전체
            else if ((idx !== 0) && !prevOptionValues[idx] && !prevOptionValues[0] && (count === options.length - 2)) {
                setVisibleText(options[0].title);
                setSelectedChannels([options[0]]);
                return [true, true, true]; 
            } // checked "전체" 선택 시 -> no option
            else if ((idx === 0) && prevOptionValues[0]) {
                setVisibleText('');
                setSelectedChannels(['']);
                return [false, false, false];
            } // "전체" 이외의 항목 선택 && "전체" checked -> "전체" uncheck
            else if ((idx !== 0) && prevOptionValues[0]) {
                newOptionValues[0] = false;
                const selectedOptions = options.filter((_, idx) => newOptionValues[idx]);
                setVisibleText(selectedOptions.map(option => option.title).join(', '));
                setSelectedChannels(selectedOptions);
                return newOptionValues;
            } else {
                const selectedOptions = options.filter((_, idx) => newOptionValues[idx]);
                setVisibleText(selectedOptions.map(option => option.title).join(', '));
                setSelectedChannels(selectedOptions);
                return newOptionValues;
            }
        });
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsExpanded(false);
            }
        };
        
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
    }, []);

    return (
        <div ref={dropdownRef} className={dropdownStyle.Dropdown__Container}>
            <div 
                className={dropdownStyle.Dropdown__Input}
                onClick={toggleDropdown}
            >
                <p className='h10 fc-gray-700'>{visibleText}</p>
                <img src={`${isExpanded ? '/img/units/custom-expanded.png' : '/img/units/custom-expand.png'}`} width={20} height={20} alt=''/>
            </div>
            { isExpanded ? (
                <div className={dropdownStyle.Dropdown__ItemList}>
                    {
                        options.map((option, key) => (
                            <div 
                                className={`${selected === key ? dropdownStyle.Dropdown__Item__selected : dropdownStyle.Dropdown__Item__default}`}
                                onClick={() => handleOptionClick(key)}
                                key={key}
                            >
                                <img src={`${optionValues[key] ? '/img/units/checkbox_checked.png' : '/img/units/checkbox_unchecked.png'}`} width={20} height={20} alt=''/>
                                <div className='spacing-4'/>
                                <p className='h10 fc-gray-700'>{option.title}</p>
                            </div>
                        ))
                    }
                </div> 
            ) :
            null}
        </div>
    );
}

export default Dropdown;
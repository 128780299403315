import axios from "axios";

export const fetchDashboardData = async (startDate, endDate, channel, ptid) => {
    const dbResponses = [null, null, null];

    try {
        const lineSresponse = await axios.get(
            process.env.REACT_APP_INDICATOR_URL + '/line/small', {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    channel: channel,
                    partnerId: ptid,
                },
                headers: {}
            }
        )
        dbResponses[0] = lineSresponse.data.data;

        const lineLresponse = await axios.get(
            process.env.REACT_APP_INDICATOR_URL + '/line/large', {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    channel: channel,
                    partnerId: ptid,
                },
                headers: {}
            }
        )
        dbResponses[1] = lineLresponse.data.data;

        const barLresponse = await axios.get(
            process.env.REACT_APP_INDICATOR_URL + '/bar/large', {
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    channel: channel,
                    partnerId: ptid,
                },
                headers: {}
            }
        )
        dbResponses[2] = barLresponse.data.data;

        return dbResponses;
    } catch (error) {
        console.error(`Error while calling getIndicator API: ${error}`);
    }
}
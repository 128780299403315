import React, { useState, useEffect } from 'react';
import { TopAccodian } from '../Units/Accodian';
import { BetaFeatures } from './BetaFeatures';
import { experimentInitSettings } from '../../data/unitsData';
import { fetchExperimentInfo, updateExperimentInfo } from '../../apis/services/create/api';
import unitsStyle from '../../style/units.module.css';
import Toast from '../Units/Toast';
import designModeStyle from '../../style/designMode.module.css';
import { ConfirmModal } from '../Units/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
//import CustomPrompt from '../Units/CustomPrompt';

const Experiment = () => {
    const navigate = useNavigate();
    const partnerId = sessionStorage.getItem('ptid');
    const chatbotId = sessionStorage.getItem('cbid');
    const userAccessLevel = sessionStorage.getItem('l');
    const [initExpSettings, setInitExpSettings] = useState();
    const [isContentOpen, setIsContentOpen] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isToastVisible, setIsToastVisible] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [pendingNavigation, setPendingNavigation] = useState(null);

    // 유료 플랜: active - off -> active - on
    // 미결제: disabled - off -> redirection

    const updateExpSetting = (key, newValue) => {
        setInitExpSettings((prev) => ({
            ...prev,
            [key]: newValue,
        }));
    }

    useEffect(() => {
        fetchExperimentInfo(partnerId, chatbotId)
            .then(res => {
                if (!res) {
                    res = experimentInitSettings;
                    updateExpSetting('detailedResponse', res.detailedResponse);
                } else {
                    res.forEach(feature => {
                        updateExpSetting(feature.key, feature);
                    })
                }
            })
    }, []);

    /* useEffect(() => {
        const unblock = navigate((nextLocation) => {
            if (unsavedChanges) {
                setIsModalVisible(true);
                setPendingNavigation(() => () => navigate(nextLocation));
                return false;
            }
            return true;
        })

        return () => {
            if (unblock) {
                unblock();
            }
        };
    }, [unsavedChanges, navigate]); */

    const handlePromptConfirm = () => {
        setIsModalVisible(false);
        setUnsavedChanges(false);
    };
    
    const handlePromptCancel = () => {
        setIsModalVisible(false);
        setPendingNavigation(null);
    };

    const betaProps = {
        initExpSettings: initExpSettings,
        updateExpSetting: updateExpSetting,
        isContentOpen: isContentOpen,
        setIsContentOpen: setIsContentOpen,
        userAccessLevel: userAccessLevel,
        setIsToastVisible: setIsToastVisible,
        setUnsavedChanges: setUnsavedChanges,
    }

    return (
        <>
            {/* <CustomPrompt 
                isBlocked={unsavedChanges}
                isModalOpen={isModalVisible}
                setIsModalOpen={setIsModalVisible}
            /> */}
            {/* {
                isModalVisible &&
                <div className={designModeStyle.Modal__Background}>
                    <div className={designModeStyle.Modal__Wrap}>
                        <ConfirmModal 
                            title={'페이지를 나가시겠습니까?'} 
                            subText={'변경사항이 저장되지 않을 수 있습니다'} 
                            submitFunc={()=>{}} 
                            cancelFunc={() => {}}
                            setIsModalVisible={setIsModalVisible}
                            confirmText={'네, 배포하기'}
                            cancelText={'아니요, 설치 가이드로 이동'}
                        />
                    </div>
                </div>
            } */}
            <div style={{width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <div style={{width: '100%', maxWidth: '1044px', minWidth: '954px', marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative'}}>
                    <TopAccodian 
                        title={'기능 설정'} 
                        badgeText={'Beta'} 
                        ContentComp={BetaFeatures}
                        subText={'출시 이전의 베타 기능을 먼저 체험해보세요'}
                        compProps={betaProps}
                    />
                </div>
                {
                    isToastVisible &&
                    <div style={{position: 'absolute', bottom: '80px', display: 'flex', width: '100%', justifyContent: 'center'}}>
                        <Toast status={'success'} message={'변경 사항이 반영되었습니다'} isLinkable={false} />
                    </div>
                }
            </div>
        </>
    );
};

export default Experiment;
import { ErrorMessage } from "../../components/Units/Alerts.jsx";

export const getErrorMessage = (location) => {
    // temp exceptional for dlst
    const partnerType = sessionStorage.getItem('ptt');
    if (partnerType === 'dlst') return;

    let text;
    let state;
    const initializeState = sessionStorage.getItem('initializeState');
    const isAnalyticsReady = sessionStorage.getItem('isAnalytisReady');
    const isDeployed = JSON.parse(sessionStorage.getItem('isDeployed'));
    const userAccessLevel = sessionStorage.getItem('l');
    const isExpired = JSON.parse(sessionStorage.getItem('isExp'));

    if (isExpired) {state = 'expired'}
    else if (isDeployed) {state = 'deployed'}
    else if (Number(initializeState) < 2) {state = 'loading'}
    else if (Number(initializeState) === 2) {state = 'ready'}
    
    const textData = {
        '/home': {
            'loading': '인공지능 점원 젠투가 만들어지는 동안, 예시 에이전트를 사용해 보세요',
            'ready': null,
            'expired': '체험 기간이 종료되어 사용이 불가합니다. ‘유료 전환 신청하기’ 버튼을 통해 활성화할 수 있습니다',
            'deployed': null,
        },
        '/home/dashboard': {
            'loading': '인공지능 점원 젠투를 배포한 후에 이용할 수 있는 탭입니다. 그 전까진 예시 데이터가 표시됩니다.',
            'ready': '인공지능 점원 젠투를 배포한 후에 이용할 수 있는 탭입니다. 그 전까진 예시 데이터가 표시됩니다.',
            'expired': '인공지능 점원 젠투를 배포한 후에 이용할 수 있는 탭입니다. 그 전까진 예시 데이터가 표시됩니다.',
            'deployed': null,
        },
        '/home/analytics': {
            'loading': '인공지능 점원 젠투를 배포한 후에 이용할 수 있는 탭입니다. 그 전까진 예시 데이터가 표시됩니다.',
            'ready': '인공지능 점원 젠투를 배포한 후에 이용할 수 있는 탭입니다. 그 전까진 예시 데이터가 표시됩니다.',
            'expired': '인공지능 점원 젠투를 배포한 후에 이용할 수 있는 탭입니다. 그 전까진 예시 데이터가 표시됩니다.',
            'deployed': null,
        }
    }

    switch (location) {
        case '/home':
            text = textData["/home"][state];
            break;
        case '/home/dashboard':
            text = textData["/home/dashboard"][state];
            break;
        case '/home/analytics':
            text = textData["/home/analytics"][state];
            break;
        default:
            break;
    }

    if (text) {return <ErrorMessage text={text} />}
}
import { useState } from "react";
import unitsStyle from '../../style/units.module.css';

export const CustomTooltip = (props) => {
    const { tipText } = props;
    const [tipVisible, setTipVisible] = useState(false);

    const handleMouseEnter = () => {
        setTipVisible(true);
    }

    const handleMouseLeave = () => {
        setTipVisible(false);
    }

    return (
        <div className={unitsStyle.CustomTooltip__Wrap}>
            <div className={unitsStyle.CustomTooltip__Icon} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <img src="/img/units/tooltip-question-mark.png" width={20} height={20} padding={4} />
            </div>
            {
                tipVisible ?
                <div className={unitsStyle.CustomTooltip__TipBox}>
                    <p className='h10 fc-gray-300' style={{ textAlign: 'start', whiteSpace: 'pre-wrap'}}>
                        {tipText}
                    </p>
                </div> :
                null
            }
        </div>
    )
}

export const CustomRefreshTooltip = (props) => {
    const { tipText, isGenerating } = props;
    const [tipVisible, setTipVisible] = useState(false);

    const handleMouseEnter = () => {
        setTipVisible(true);
    }

    const handleMouseLeave = () => {
        setTipVisible(false);
    }

    return (
        <div className={unitsStyle.CustomTooltip__Wrap} style={{width: '28px', height: '28px'}}>
            {
                isGenerating ?
                <img src='/img/units/spinner_28px.gif' width={28} height={28} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{cursor: 'default'}}/> :
                <img src='/img/units/refresh-default.png' width={28} height={28} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{cursor: 'pointer'}}/>
            }
            {
                tipVisible ?
                <div className={unitsStyle.CustomTooltip__RefreshTipBox}>
                    <p className='h12 fc-gray-300' style={{ textAlign: 'start', whiteSpace: 'pre-wrap'}}>
                        {tipText}
                    </p>
                </div> :
                null
            }
        </div>
    )
}